import {
    Box,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Modal,
    Typography,
  } from "@material-ui/core";
  import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
  import React from "react";
  
  const ViewCommissionsModalMerchant = ({
    transaction,
    openModal,
    setOpenModal,
  }) => {
    const bold = { fontWeight: 700 };
  
    const handleClose = () => {
      setOpenModal(false);
    };
    return (
      <>
        <Dialog
          open={openModal}
          fullWidth
          maxWidth={"sm"}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{
            "& .MuiDialog-paper": {
              px: "1.2em",
              py: "1em",
              borderRadius: "8px",
            },
          }}
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{
              fontSize: "18px",
              fontWeight: 500,
              color: "grey.dark",
              marginBottom: "1em",
              justifyContent: "flex-end",
              display: "flex",
              "& svg": {
                padding: "0",
              },
            }}
          >
            <IconButton
              aria-label="close"
              size="large"
              style={{ fontSize: "1.4rem" }}
              onClick={handleClose}
            >
              <ClearRoundedIcon fontSize="inherit" />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{ color: "#525f7f" }}>
            {transaction ? (
              <>
                <Typography component="p" variant="body1">
                  <span style={bold}>Mtn</span>:{" "}
                  {transaction.mtn}
                </Typography>
                <Typography component="p" variant="body1">
                  <span style={bold}>Airtel</span>:{" "}
                  {transaction.airtel}
                </Typography>
                <Typography component="p" variant="body1">
                  <span style={bold}>Glo</span>:{" "}
                  {transaction.glo}
                </Typography>
                <Typography component="p" variant="body1">
                  <span style={bold}>9mobile</span>: {transaction['9mobile']}
                </Typography>
                <Typography component="p" variant="body1">
                  <span style={bold}>Spectranet</span>: {transaction.spectranet}
                </Typography>
                <Typography component="p" variant="body1">
                  <span style={bold}>Smile</span>: {transaction.smile}
                </Typography>
                <Typography component="p" variant="body1">
                  <span style={bold}>DSTV</span>: {transaction.dstv}
                </Typography>
                <Typography component="p" variant="body1">
                  <span style={bold}>GOTV</span>:{" "}
                  {transaction.gotv}
                </Typography>
                <Typography component="p" variant="body1">
                  <span style={bold}>Startimes</span>: {transaction.startimes}
                </Typography>
                <Typography component="p" variant="body1">
                  <span style={bold}>Ibadan Prepaid</span>:{" "}
                  {transaction.ibadan_prepaid}
                </Typography>
                <Typography component="p" variant="body1">
                  <span style={bold}>Ibadan Postpaid</span>: {transaction.ibadan_postpaid}
                </Typography>
                <Typography component="p" variant="body1">
                  <span style={bold}>Eko Prepaid</span>:{" "}
                  {transaction.eko_prepaid}
                </Typography>
                <Typography component="p" variant="body1">
                  <span style={bold}>Eko Postpaid</span>: {transaction.eko_postpaid}
                </Typography>
                <Typography component="p" variant="body1">
                  <span style={bold}>Ikeja Prepaid</span>: {transaction.ikeja_prepaid}
                </Typography>
                <Typography component="p" variant="body1">
                  <span style={bold}>Ikeja Postpaid</span>: {transaction.ikeja_postpaid}
                </Typography>
                <Typography component="p" variant="body1">
                  <span style={bold}>Portharcourt Prepaid</span>: {transaction.portharcourt_prepaid}
                </Typography>
                <Typography component="p" variant="body1">
                  <span style={bold}>Portharcourt Postpaid</span>: {transaction.portharcourt_postpaid}
                </Typography>
                <Typography component="p" variant="body1">
                  <span style={bold}>Jos Prepaid</span>: {transaction.jos_prepaid}
                </Typography>
                <Typography component="p" variant="body1">
                  <span style={bold}>Jos Postpaid</span>: {transaction.jos_postpaid}
                </Typography>
                <Typography component="p" variant="body1">
                  <span style={bold}>Benin Prepaid</span>: {transaction.benin_prepaid}
                </Typography>
                <Typography component="p" variant="body1">
                  <span style={bold}>Benin Postpaid</span>: {transaction.benin_postpaid}
                </Typography>
                <Typography component="p" variant="body1">
                  <span style={bold}>Kaduna Prepaid</span>: {transaction.kaduna_prepaid}
                </Typography>
                <Typography component="p" variant="body1">
                  <span style={bold}>Kaduna Postpaid</span>: {transaction.kaduna_postpaid}
                </Typography>
                <Typography component="p" variant="body1">
                  <span style={bold}>Kano Prepaid</span>: {transaction.kano_prepaid}
                </Typography>
                <Typography component="p" variant="body1">
                  <span style={bold}>Kano Postpaid</span>: {transaction.kano_postpaid}
                </Typography>
                <Typography component="p" variant="body1">
                  <span style={bold}>Kano Postpaid MD</span>: {transaction.kano_postpaid_md}
                </Typography>
                <Typography component="p" variant="body1">
                  <span style={bold}>Aedc Prepaid</span>: {transaction.aedc_prepaid}
                </Typography>
                <Typography component="p" variant="body1">
                  <span style={bold}>Aedc Postpaid</span>: {transaction.aedc_postpaid}
                </Typography>
                <Typography component="p" variant="body1">
                  <span style={bold}>Eedc Prepaid</span>: {transaction.eedc_prepaid}
                </Typography>
                <Typography component="p" variant="body1">
                  <span style={bold}>Eedc Postpaid</span>: {transaction.eedc_postpaid}
                </Typography>
                <Typography component="p" variant="body1">
                  <span style={bold}>Access power</span>: {transaction.accesspower}
                </Typography>
                <Typography component="p" variant="body1">
                  <span style={bold}>Cel</span>: {transaction.cel}
                </Typography>
                <Typography component="p" variant="body1">
                  <span style={bold}>Bank Collections</span>: {transaction.bank_collections}
                </Typography>
                <Typography component="p" variant="body1">
                  <span style={bold}>USSD Collections</span>: {transaction.ussd_collections}
                </Typography>
              </>
            ) : (
              <Box
                style={{
                  display: "flex",
                  paddingBlock: "2em",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </DialogContent>
        </Dialog>
      </>
    );
  };
  
  export default ViewCommissionsModalMerchant;
  