import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Modal,
  Typography,
} from "@material-ui/core";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import React from "react";

const TransactionModalTransfer = ({ transaction, openModal, setOpenModal }) => {
  const bold = { fontWeight: 700 };

  const handleClose = () => {
    setOpenModal(false);
  };
  return (
    <>
      <Dialog
        open={openModal}
        fullWidth
        maxWidth={"sm"}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          "& .MuiDialog-paper": {
            px: "1.2em",
            py: "1em",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            fontSize: "18px",
            fontWeight: 500,
            color: "grey.dark",
            marginBottom: "1em",
            justifyContent: "flex-end",
            display: "flex",
            "& svg": {
              padding: "0",
            },
          }}
        >
          <IconButton
            aria-label="close"
            size="large"
            style={{ fontSize: "1.4rem" }}
            onClick={handleClose}
          >
            <ClearRoundedIcon fontSize="inherit" />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ color: "#525f7f" }}>
          {transaction ? (
            <>
              <Typography
                component="p"
                variant="h6"
                style={{
                  fontWeight: 700,
                  textTransform: "uppercase",
                }}
              >
                {transaction.title}
              </Typography>
              <Typography component="p" variant="body1">
                {transaction.time_stamp}
              </Typography>
              <Typography component="p" variant="body1">
                <span style={bold}>Reference Number</span>:{" "}
                {transaction.reference_num}
              </Typography>
              <Typography component="p" variant="body1">
                <span style={bold}>Transaction ID</span>:{" "}
                {transaction.transaction_id}
              </Typography>
              <Typography component="p" variant="body1">
                <span style={bold}>Bank</span>: {transaction.bank}
              </Typography>{" "}
              <Typography component="p" variant="body1">
                <span style={bold}>Status</span>: {transaction.status}
              </Typography>
              <Typography component="p" variant="body1">
                <span style={bold}>Source</span>: {transaction.source}
              </Typography>
              <Typography component="p" variant="body1">
                <span style={bold}>Payment Reference</span>:{" "}
                {transaction.payment_ref}
              </Typography>{" "}
              <Typography component="p" variant="body1">
                <span style={bold}>Vender Reference</span>:{" "}
                {transaction.vendor_reference}
              </Typography>{" "}
              <Typography component="p" variant="body1">
                <span style={bold}>Vended by</span>: {transaction.vended_by}
              </Typography>
              <Typography component="p" variant="body1">
                <span style={bold}>Amount</span>: {transaction.amount}
              </Typography>{" "}
              <Typography component="p" variant="body1">
                <span style={bold}>Amount Dispense</span>:{" "}
                {transaction.amount_dispense}
              </Typography>{" "}
              <Typography component="p" variant="body1">
                <span style={bold}>Amount Paid</span>: {transaction.amount_paid}
              </Typography>
              <Typography component="p" variant="body1">
                <span style={bold}>Merchant Name</span>:{" "}
                {transaction.merchant_name}
              </Typography>{" "}
              <Typography component="p" variant="body1">
                <span style={bold}>Merchant ID</span>: {transaction.merchant_id}
              </Typography>{" "}
              <Typography component="p" variant="body1">
                <span style={bold}>Customer Name</span>:{" "}
                {transaction.customer_name}
              </Typography>{" "}
              <Typography component="p" variant="body1">
                <span style={bold}>Phone</span>: {transaction.phone}
              </Typography>{" "}
              <Typography component="p" variant="body1">
                <span style={bold}>Receiver</span>: {transaction.receiver}
              </Typography>{" "}
              <Typography component="p" variant="body1">
                <span style={bold}>API Reference id</span>:{" "}
                {transaction.api_reference_id}
              </Typography>{" "}
              <Typography component="p" variant="body1">
                <span style={bold}>API used</span>: {transaction.api_used}
              </Typography>{" "}
              <Typography component="p" variant="body1">
                <span style={bold}>Category</span>: {transaction.category}
              </Typography>{" "}
              <Typography component="p" variant="body1">
                <span style={bold}>Device ID</span>: {transaction.device_id}
              </Typography>{" "}
              <Typography component="p" variant="body1">
                <span style={bold}>Email</span>: {transaction.email}
              </Typography>{" "}
              <Typography component="p" variant="body1">
                <span style={bold}>Payment method</span>:{" "}
                {transaction.payment_method}
              </Typography>{" "}
              <Typography component="p" variant="body1">
                <span style={bold}>Platform used</span>:{" "}
                {transaction.platform_used}
              </Typography>{" "}
              <Typography component="p" variant="body1">
                <span style={bold}>Registered user</span>:{" "}
                {transaction.registered_user}
              </Typography>{" "}
              <Typography component="p" variant="body1">
                <span style={bold}>Time dispensed</span>:{" "}
                {transaction.time_dispensed}
              </Typography>{" "}
              <Typography component="p" variant="body1">
                <span style={bold}>Remark</span>: {transaction.remark}
              </Typography>{" "}
            </>
          ) : (
            <Box
              style={{
                display: "flex",
                paddingBlock: "2em",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TransactionModalTransfer;
