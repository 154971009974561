/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { ThemeProvider, createTheme } from "@material-ui/core";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "assets/css/argon-dashboard-react.css";
// import "assets/scss/argon-dashboard-react.scss";
// import "react-datetime/css/react-datetime.css";

import AdminLayout from "layouts/Admin.jsx";
import AuthLayout from "layouts/Auth.jsx";

export const baseUrl = "https://irecharge.com.ng/api/cms";

const theme = createTheme({
  palette: {
    secondary: {
      main: "#D34829",
    },
    primary: {
      main: "#52AB64",
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <HashRouter>
      <Switch>
        <Redirect exact from="/" to="/auth/login" />
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Redirect from="/" to="/admin/index" />
      </Switch>
    </HashRouter>
  </ThemeProvider>,
  document.getElementById("root")
);
