import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  //   DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import React from "react";

const TransactionModal = ({ log, openModal, setOpenModal }) => {
  const classes = {
    bold: { fontWeight: 700 },
    mb: { marginBottom: 16 },
    my: { marginBottom: 16, marginTop: 16 },
  };

  const handleClose = () => {
    setOpenModal(false);
  };
  return (
    <>
      <Dialog
        open={openModal}
        fullWidth
        maxWidth={"sm"}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            px: "1.2em",
            py: "1em",
            borderRadius: "8px",
          },
        }}
      >
        <DialogContent style={{ color: "#525f7f", paddingBottom: "2em" }}>
          {log ? (
            <>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 24,
                }}
              >
                <Box>
                  <Typography
                    component="p"
                    variant="h6"
                    style={{
                      fontWeight: 700,
                      textTransform: "uppercase",
                    }}
                  >
                    View Log
                  </Typography>
                </Box>

                <Box>
                  <IconButton
                    aria-label="close"
                    size="large"
                    style={{ fontSize: "1.4rem" }}
                    onClick={handleClose}
                  >
                    <ClearRoundedIcon fontSize="inherit" />
                  </IconButton>
                </Box>
              </Box>
              <Typography component="p" variant="body1" style={classes.mb}>
                <span style={classes.bold}>Request Time</span>:{" "}
                {log.request_time}
              </Typography>
              <Typography
                component="p"
                variant="body1"
                style={{ marginBottom: 32 }}
              >
                <span style={classes.bold}>Request</span>: {log.request_body}
              </Typography>
              <Typography component="p" variant="body1" style={classes.mb}>
                <span style={classes.bold}>Response Time</span>:{" "}
                {log.response_time}
              </Typography>{" "}
              <Typography component="p" variant="body1" style={classes.mb}>
                <span style={classes.bold}>Response</span>: {log.response_bpdy}
              </Typography>
            </>
          ) : (
            <Box
              style={{
                display: "flex",
                paddingBlock: "2em",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TransactionModal;
