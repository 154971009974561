import React, { useState } from "react";
import {
  Grid,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import CryptoJS from "crypto-js";
import Swal from "sweetalert2";
import RedoModal from "../../components/Modal/RedoModal";
import TransactionModalWalletTopup from "../../components/Modal/TransactionModalWalletTopup";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EditModalWalletTopup from "../../components/Modal/EditModalWalletTopup";
import RedoModalWalletTopup from "../../components/Modal/RedoModalWalletTopup";
import RefundModalSignupFees from "../../components/Modal/RefundModalSignupFees";
import ViewCommissionsModalMerchant from "../../components/Modal/ViewCommissionsModalMerchant";
import EditModalCommissionsMerchant from "../../components/Modal/EditModalCommissionsMerchant";
import SetApprovalModalMerchant from "../../components/Modal/SetApprovalModalMerchants";
import ViewModalMerchant from "../../components/Modal/ViewModalMerchant";
import EditModalMerchant from "../../components/Modal/EditModalMerchant";

const MerchantItem = ({
  transaction: {
    id,
    title,
    status,
    remarks,
    merchant_name,
    merchant_id,
    state,
    approved_by,
    date_approved,
    address,
    phone,
    email,
    primary_contact_name,
    primary_contact_phone,
    primary_user,
    wallet_balance,
    activation_code
  },
  refresh,
}) => {
  const bold = { fontWeight: 700 };
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [openCommissionsModal, setOpenCommissionsModal] = React.useState(false);
  //   const [openViewLogModal, setOpenViewLogModal] = React.useState(false);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [openEditCommissionsModal, setOpenEditCommissionsModal] = React.useState(false);
    const [openRefundModal, setOpenRefundModal] = React.useState(false);
    const [openApprovalModal, setOpenApprovalModal] = React.useState(false);
  const [openRedoModal, setOpenRedoModal] = React.useState(false);
  const [transactionState, setTransactionState] = useState(null);
  const [commissionsState, setCommissionsState] = useState(null);
  //   const [logState, setLogState] = useState(null);
  const [refundData, setRefundData] = useState(null);
  const [approvalData, setApprovalData] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleModal = (event) => {
    handleClose();
    setOpenModal(true);
    handleGetTransaction(event, id);
  };

  const handleViewCommissionsModal = (event) => {
    handleClose();
    setOpenCommissionsModal(true);
    handleGetCommissions(event, id)
  }

  const handleEditModal = (event) => {
    handleClose();
    setOpenEditModal(true);
    handleGetTransaction(event, id);
    // handleGetTransaction(event, id);
  };

  const handleEditCommissionsModal = (event) => {
    handleClose();
    setOpenEditCommissionsModal(true);
    handleGetCommissions(event, id);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  //   const handleViewLogModal = (event) => {
  //     setLogState(null);
  //     handleClose();
  //     setOpenViewLogModal(true);
  //     handleViewLog(event, id);
  //   };

    const handleRefundSignupFees = () => {
      setRefundData({ id, title, merchant_name });
      handleClose();
      setOpenRefundModal(true);
      // handleGetTransaction(event, id);
    };

    const handleSetApproval = () => {
      setApprovalData({id, merchant_name });
      handleClose();
      setOpenApprovalModal(true)
    }

  const handleRedoModal = () => {
    handleClose();
    setOpenRedoModal(true);
  };

  // console.log(transactionState);

  const handleGetTransaction = async (event, id) => {
    event.preventDefault();
    setLoading(true);

    try {
      const token = sessionStorage.getItem("token");
      const passcode = CryptoJS.HmacSHA512(token, "12CMS34Sx").toString();

      const data = {
        passcode,
        id,
        token,
      };

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/merchant/view.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const { message, status, data: transactionData } = await response.json();
      if (status === "00") {
        setTransactionState(transactionData);
      } else if (status === "99") {
        Swal.fire({
          icon: "error",
          text: message,
          position: "top-end",
          confirmButtonColor: "#52ab64",
        });
        sessionStorage.clear();
        // props.history.replace("/auth/login");
      } else {
        Swal.fire({
          icon: "error",
          text: message,
          position: "top-end",
          confirmButtonColor: "#52ab64",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Unable to connect",
        text: "Please check your internet connection and try again",
        confirmButtonColor: "#52ab64",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleGetCommissions = async (event, id) => {
    event.preventDefault();
    setLoading(true);

    try {
      const token = sessionStorage.getItem("token");
      const passcode = CryptoJS.HmacSHA512(token, "12CMS34Sx").toString();

      const data = {
        passcode,
        id,
        token,
      };

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/merchant/view_commissions.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const { message, status, data: transactionData } = await response.json();
      if (status === "00") {
        setCommissionsState(transactionData);
      } else if (status === "99") {
        Swal.fire({
          icon: "error",
          text: message,
          position: "top-end",
          confirmButtonColor: "#52ab64",
        });
        sessionStorage.clear();
        // props.history.replace("/auth/login");
      } else {
        Swal.fire({
          icon: "error",
          text: message,
          position: "top-end",
          confirmButtonColor: "#52ab64",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Unable to connect",
        text: "Please check your internet connection and try again",
        confirmButtonColor: "#52ab64",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Grid
        container
        justifyContent="space-between"
        style={{
          border: "1px solid #6D7893",
          borderRadius: 5,
          margin: "8px 0",
          padding: 8,
          backgroundColor: "#fcfcfc",
        }}
      >
        <Grid item xs={8}>
          <Typography
            component="p"
            variant="h6"
            style={{
              fontWeight: 700,
              textTransform: "uppercase",
            }}
          >
            {title}
          </Typography>
          <Typography component="p" variant="body1">
            <span style={bold}>Merchant Name</span>: {merchant_name}
          </Typography>
          <Typography component="p" variant="body1">
            <span style={bold}>State</span>: {state}
          </Typography>
          <Typography component="p" variant="body1">
            <span style={bold}>Approved By</span>: {approved_by}
          </Typography>
          <Typography component="p" variant="body1">
            <span style={bold}>Date Approved</span>: {date_approved}
          </Typography>
          <Typography component="p" variant="body1">
            <span style={bold}>Address</span>: {address}
          </Typography>
          <Typography component="p" variant="body1">
            <span style={bold}>Status</span>: {status}
          </Typography>
          <Typography component="p" variant="body1">
            <span style={bold}>Remarks</span>: {remarks}
          </Typography>
          <Typography component="p" variant="body1">
            <span style={bold}>Phone</span>: {phone}
          </Typography>
          <Typography component="p" variant="body1">
            <span style={bold}>Email</span>: {email}
          </Typography>
          <Typography component="p" variant="body1">
            <span style={bold}>Primary Contact Name</span>: {primary_contact_name}
          </Typography>
          <Typography component="p" variant="body1">
            <span style={bold}>Primary Contact Phone</span>: {primary_contact_phone}
          </Typography>
          
          <Typography component="p" variant="body1">
            <span style={bold}>Primary User</span>: {primary_user}
          </Typography>
          <Typography component="p" variant="body1">
            <span style={bold}>Wallet Balance</span>: {wallet_balance}
          </Typography>
          <Typography component="p" variant="body1">
            <span style={bold}>Activation Code</span>: {activation_code}
          </Typography>
        </Grid>
        <Grid item xs={4} style={{ textAlign: "right" }}>
          <IconButton
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            color="primary"
          >
            <MoreVertIcon fontSize="large" />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleSetApproval}>Approve/Reject/Reset</MenuItem> 
            <MenuItem onClick={handleModal}>View Merchant</MenuItem> 
            <MenuItem onClick={handleEditModal}>Edit Merchant</MenuItem> 
            <MenuItem onClick={handleRefundSignupFees}>Refund Signup Fees</MenuItem> 
            <MenuItem onClick={handleViewCommissionsModal}>View Commissions</MenuItem> 
            <MenuItem onClick={handleEditCommissionsModal}>Set Commissions</MenuItem> 
          </Menu>
        </Grid> 
      </Grid>

      {openModal && (
        <ViewModalMerchant
          openModal={openModal}
          setOpenModal={setOpenModal}
          transaction={transactionState}
        />
      )}
      {openCommissionsModal && (
        <ViewCommissionsModalMerchant
          openModal={openCommissionsModal}
          setOpenModal={setOpenCommissionsModal}
          transaction={commissionsState}
        />
      )}

      {/* {openViewLogModal && (
        <ViewLogModal
          openModal={openViewLogModal}
          setOpenModal={setOpenViewLogModal}
          log={logState}
        />
      )} */}
      {/* <EditBranch
        close={() => setOpenEditModal(false)}
        open={openEditModal}
        transaction={transactionState}
        refresh={refresh}
        type="vtu"
      /> */}

      {/* <EditModalWalletTopup
        close={() => setOpenEditModal(false)}
        open={openEditModal}
        transaction={transactionState}
        refresh={refresh}
      /> */}

      <EditModalCommissionsMerchant 
        close={() => setOpenEditCommissionsModal(false)} 
        open={openEditCommissionsModal} 
        transaction={commissionsState} 
        refresh={refresh}
        merchantName={merchant_name}
      />

      <EditModalMerchant
        close={() => setOpenEditModal(false)}
        open={openEditModal}
        transaction={transactionState}
        refresh={refresh}
      />

      {openRefundModal && (
        <RefundModalSignupFees
          close={() => setOpenRefundModal(false)}
          open={openRefundModal}
          transaction={transactionState}
          data={refundData}
          refresh={refresh}
          type="vtu"
        />
      )}

      {openApprovalModal && (
        <SetApprovalModalMerchant 
          close={() => setOpenApprovalModal(false)} 
          open={openApprovalModal} 
          data={approvalData}
          refresh={refresh}
        />
      )}
      {/* {openRedoModal && (
        <RedoModalWalletTopup
          close={() => setOpenRedoModal(false)}
          open={openRedoModal}
          transaction={transactionState}
          data={{ title, id }}
          refresh={refresh}
        />
      )} */}
    </div>
  );
};

export default MerchantItem;
