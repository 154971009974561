import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import CancelIcon from "@material-ui/icons/Cancel";
import { IconButton, TextareaAutosize } from "@material-ui/core";
import StoreIcon from "@material-ui/icons/Store";
import {
  Typography,
  TextField,
  Box,
  FormLabel,
  FormControl,
  CircularProgress,
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import RadioGroup from "@material-ui/core/RadioGroup";
import CryptoJS from "crypto-js";
import Swal from "sweetalert2";

const GreenRadio = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  formStyle: {
    border: "1px solid rgba(0,0,0,0.1)",
  },
  text: {
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  confirmBtn: {
    width: "100%",
    background: "#28AA63",
    color: "white",
    marginBottom: "1rem",
    padding: "1rem",
    "&:hover": {
      background: "#28AA63",
    },
  },
  logo: {
    textAlign: "center",
  },
  textfield: {
    padding: "1rem 1rem",
    border: "1px solid #F8FAFC",
  },
  formLabel: {
    textAlign: "left",
    fontSize: "14px",
    fontWeight: "500",
  },
  formControl2: {
    width: "100%",
    marginBottom: "1rem",
  },
  topTitle: {
    borderBottom: "1px solid #C4C4C4",
  },
  title: {
    paddingTop: "4px",
    paddingLeft: "4px",
  },
  content: {
    paddingTop: "2rem",
  },
  checkBox: {
    padding: "0px 2px 0px 6px",
  },
}));

export default function EditModalCommissionsMerchant(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [mtn, setMtn] = useState("")
  const [airtel, setAirtel] = useState("")
  const [glo, setGlo] = useState("");
  const [nineMobile, setNineMobile] = useState("");
  const [spectranet, setSpectranet] = useState("");
  const [smile, setSmile] = useState("");
  const [dstv, setDstv] = useState("");
  const [gotv, setGOtv] = useState("");
  const [startimes, setStartimes] = useState("") 

  const [ibadanPrepaid, setIbadanPrepaid] = useState("");
  const [ibadanPostpaid, setIbadanPostpaid] = useState("");
  const [ekoPrepaid, setEkoPrepaid] = useState("");
  const [ekoPostpaid, setEkoPostpaid] = useState("");
  const [ikejaPrepaid, setIkejaPrepaid] = useState("");
  const [ikejaPostpaid, setIkejaPostpaid] = useState("");

  const [portharcourtPrepaid, setPortharcourtPrepaid] = useState("");
  const [portharcourtPostpaid, setPortharcourtPostpaid] = useState("");
  const [josPrepaid, setJosPrepaid] = useState("");
  const [jostPostpaid, setJosPostpaid] = useState("");
  const [beninPrepaid, setBeninPrepaid] = useState("");
  const [beninPostpaid, setBeninPostpaid] = useState("");
  const [kadunaPrepaid, setKadunaPrepaid] = useState("");
  const [kadunaPostpaid, setKadunaPostpaid] = useState("");

  const [kanoPrepaid, setKanoPrepaid] = useState("");
  const [kanoPostpaid, setKanoPostpaid] = useState("");
  const [kanoPostpaidMd, setKanoPostPaidMd] = useState("");
  const [aedcPrepaid, setAedcPrepaid] = useState("");
  const [aedcPostpaid, setAedcPostpaid] = useState("");
  const [eedcPrepaid, setEedcPrepaid] = useState("");
  const [eedcPostpaid, setEedcPostpaid] = useState("");

  const [accesspower, setAccesspower] = useState("");
  const [cel, setCel] = useState("");
  const [bankCollections, setBankCollections] = useState("");
  const [ussdCollections, setUssdCollections] = useState("");


  useEffect(() => {
    if (props.transaction) {
        setMtn(props.transaction.mtn)
        setAirtel(props.transaction.airtel)
        setGlo(props.transaction.glo)
        setNineMobile(props.transaction['9mobile'])
        setSpectranet(props.transaction.spectranet);
        setSmile(props.transaction.smile);
        setDstv(props.transaction.dstv);
        setGOtv(props.transaction.gotv);
        setStartimes(props.transaction.startimes);

        setIbadanPrepaid(props.transaction.ibadan_prepaid)
        setIbadanPostpaid(props.transaction.ibadan_postpaid)
        setEkoPrepaid(props.transaction.eko_prepaid);
        setEkoPostpaid(props.transaction.eko_postpaid);
        setIkejaPrepaid(props.transaction.ikeja_prepaid);
        setIkejaPostpaid(props.transaction.ikeja_postpaid);

        setPortharcourtPrepaid(props.transaction.portharcourt_prepaid);
        setPortharcourtPostpaid(props.transaction.portharcourt_postpaid);
        setJosPrepaid(props.transaction.jos_prepaid);
        setJosPostpaid(props.transaction.jos_postpaid);
        setBeninPrepaid(props.transaction.benin_prepaid);
        setBeninPostpaid(props.transaction.benin_postpaid);
        setKadunaPrepaid(props.transaction.kaduna_prepaid);
        setKadunaPostpaid(props.transaction.kaduna_postpaid);

        setKanoPrepaid(props.transaction.kano_prepaid);
        setKanoPostpaid(props.transaction.kano_postpaid);
        setKanoPostPaidMd(props.transaction.kano_postpaid_md);
        setAedcPrepaid(props.transaction.aedc_prepaid);
        setAedcPostpaid(props.transaction.aedc_postpaid);
        setEedcPrepaid(props.transaction.eedc_prepaid);
        setEedcPostpaid(props.transaction.eedc_postpaid);

        setAccesspower(props.transaction.accesspower);
        setCel(props.transaction.cel);
        setBankCollections(props.transaction.bank_collections);
        setUssdCollections(props.transaction.ussd_collections)

    }
  }, [props.transaction]);

  const handleEditTransaction = async (event, id) => {
    event.preventDefault();
    setLoading(true);

    try {
      const token = sessionStorage.getItem("token");
      const passcode = CryptoJS.HmacSHA512(token, "12CMS34Sx").toString();

      const data = {
        passcode,
        id: props.transaction.id,
        token,
        mtn,
        airtel,
        glo,
        "9mobile": nineMobile,
        spectranet,
        smile,
        dstv,
        gotv,
        startimes,
        ibadan_prepaid: ibadanPrepaid,
        ibadan_postpaid: ibadanPostpaid,
        eko_prepaid: ekoPrepaid,
        eko_postpaid: ekoPostpaid,
        ikeja_prepaid: ikejaPrepaid,
        ikeja_postpaid: ikejaPostpaid,
        portharcourt_prepaid: portharcourtPrepaid,
        portharcourt_postpaid: portharcourtPostpaid,
        jos_prepaid: josPrepaid,
        jos_postpaid: jostPostpaid,
        benin_prepaid: beninPrepaid,
        benin_postpaid: beninPostpaid,
        kaduna_prepaid: kadunaPrepaid,
        kaduna_postpaid: kadunaPostpaid,
        kano_prepaid: kanoPrepaid,
        kano_postpaid: kanoPostpaid,
        kano_postpaid_md: kanoPostpaidMd,
        aedc_prepaid: aedcPrepaid,
        aedc_postpaid: aedcPostpaid,
        eedc_prepaid: eedcPrepaid,
        eedc_postpaid: eedcPostpaid,
        accesspower: accesspower,
        cel: cel,
        bank_collections: bankCollections,
        ussd_collections: ussdCollections
      };

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/merchant/set_commissions.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const { message, status, data: transactionData } = await response.json();
      if (status === "00") {
        props.refresh();
        handleClose();
        Swal.fire({
          icon: "success",
          text: message,
          position: "center",
          confirmButtonColor: "#52ab64",
        });
        // console.log(transactionData);
        // setTransactionState(transactionData);
      } else if (status === "99") {
        Swal.fire({
          icon: "error",
          text: message,
          position: "top-end",
          confirmButtonColor: "#52ab64",
        });
        sessionStorage.clear();
        // props.history.replace("/auth/login");
      } else {
        Swal.fire({
          icon: "error",
          text: message,
          position: "top-end",
          confirmButtonColor: "#52ab64",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Unable to connect",
        text: "Please check your internet connection and try again",
        confirmButtonColor: "#52ab64",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    props.close();
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={props.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.topTitle}>
          <Grid container justify="space-between">
            <Grid item xs={10}>
              <Box>
                <Grid container alignItems="center">
                  <Grid item>
                    <StoreIcon />
                  </Grid>
                  <Grid item className={classes.title}>
                    {loading ? (
                      <div>
                        <CircularProgress />
                        <span style={{ fontSize: 12, fontWeight: "normal" }}>
                          Loading..
                        </span>
                      </div>
                    ) : (
                      <Typography variant="h6">Set Commissions for <span style={{textTransform: "capitalize"}}>{props.merchantName}</span></Typography>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <IconButton onClick={handleClose}>
                <CancelIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        {props.transaction ? (
          <DialogContent className={classes.content}>
            <Box>
              <Grid container spacing={2}>
                {/* <Typography>{props.transaction.source}</Typography> */}
                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Mtn
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      type="number"
                      placeholder="Input Units"
                      className={classes.textField}
                      size="small"
                      value={mtn}
                      onChange={(e) => setMtn(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Airtel
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      type="number"
                      placeholder="Input Units"
                      className={classes.textField}
                      size="small"
                      value={airtel}
                      onChange={(e) => setAirtel(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Glo
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      type="number"
                      placeholder="Input Units"
                      className={classes.textField}
                      size="small"
                      value={glo}
                      onChange={(e) => setGlo(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      9mobile
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      type="number"
                      placeholder="Input Units"
                      className={classes.textField}
                      size="small"
                      value={nineMobile}
                      onChange={(e) => setNineMobile(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Spectranet
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      type="number"
                      placeholder="Input Units"
                      className={classes.textField}
                      size="small"
                      value={spectranet}
                      onChange={(e) => setSpectranet(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Smile
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      type="number"
                      placeholder="Input Units"
                      className={classes.textField}
                      size="small"
                      value={smile}
                      onChange={(e) => setSmile(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Dstv
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      type="number"
                      placeholder="Input Units"
                      className={classes.textField}
                      size="small"
                      value={dstv}
                      onChange={(e) => setDstv(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Gotv
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      type="number"
                      placeholder="Input Units"
                      className={classes.textField}
                      size="small"
                      value={gotv}
                      onChange={(e) => setGOtv(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Startimes
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      type="number"
                      placeholder="Input Units"
                      className={classes.textField}
                      size="small"
                      value={startimes}
                      onChange={(e) => setStartimes(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Ibadan Prepaid
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      type="number"
                      placeholder="Input Units"
                      className={classes.textField}
                      size="small"
                      value={ibadanPrepaid}
                      onChange={(e) => setIbadanPrepaid(e.target.value)}
                    />
                  </FormControl>
                </Grid>


                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Ibadan Postpaid
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      type="number"
                      placeholder="Input Units"
                      className={classes.textField}
                      size="small"
                      value={ibadanPostpaid}
                      onChange={(e) => setIbadanPostpaid(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Eko Prepaid
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      type="number"
                      placeholder="Input Units"
                      className={classes.textField}
                      size="small"
                      value={ekoPrepaid}
                      onChange={(e) => setEkoPrepaid(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Eko Postpaid
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      type="number"
                      placeholder="Input Units"
                      className={classes.textField}
                      size="small"
                      value={ekoPostpaid}
                      onChange={(e) => setEkoPostpaid(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Ikeja Prepaid
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      type="number"
                      placeholder="Input Units"
                      className={classes.textField}
                      size="small"
                      value={ikejaPrepaid}
                      onChange={(e) => setIkejaPrepaid(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Ikeja Postpaid
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      type="number"
                      placeholder="Input Units"
                      className={classes.textField}
                      size="small"
                      value={ikejaPostpaid}
                      onChange={(e) => setIkejaPostpaid(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Portharcourt Prepaid
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      type="number"
                      placeholder="Input Units"
                      className={classes.textField}
                      size="small"
                      value={portharcourtPrepaid}
                      onChange={(e) => setPortharcourtPrepaid(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Portharcourt Postpaid
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      type="number"
                      placeholder="Input Units"
                      className={classes.textField}
                      size="small"
                      value={portharcourtPostpaid}
                      onChange={(e) => setPortharcourtPostpaid(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Jos Prepaid
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      type="number"
                      placeholder="Input Units"
                      className={classes.textField}
                      size="small"
                      value={josPrepaid}
                      onChange={(e) => setJosPrepaid(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Jos Postpaid
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      type="number"
                      placeholder="Input Units"
                      className={classes.textField}
                      size="small"
                      value={jostPostpaid}
                      onChange={(e) => setJosPostpaid(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Benin Prepaid
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      type="number"
                      placeholder="Input Units"
                      className={classes.textField}
                      size="small"
                      value={beninPrepaid}
                      onChange={(e) => setBeninPrepaid(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Benin Postpaid
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      type="number"
                      placeholder="Input Units"
                      className={classes.textField}
                      size="small"
                      value={beninPostpaid}
                      onChange={(e) => setBeninPostpaid(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Kaduna Prepaid
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      type="number"
                      placeholder="Input Units"
                      className={classes.textField}
                      size="small"
                      value={kadunaPrepaid}
                      onChange={(e) => setKadunaPrepaid(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Kaduna Postpaid
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      type="number"
                      placeholder="Input Units"
                      className={classes.textField}
                      size="small"
                      value={kadunaPostpaid}
                      onChange={(e) => setKadunaPostpaid(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Kano Prepaid
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      type="number"
                      placeholder="Input Units"
                      className={classes.textField}
                      size="small"
                      value={kanoPrepaid}
                      onChange={(e) => setKanoPrepaid(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Kano Postpaid
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      type="number"
                      placeholder="Input Units"
                      className={classes.textField}
                      size="small"
                      value={kanoPostpaid}
                      onChange={(e) => setKanoPostpaid(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Kano Postpaid MD
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      type="number"
                      placeholder="Input Units"
                      className={classes.textField}
                      size="small"
                      value={kanoPostpaidMd}
                      onChange={(e) => setKanoPostPaidMd(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Aedc Prepaid
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      type="number"
                      placeholder="Input Units"
                      className={classes.textField}
                      size="small"
                      value={aedcPrepaid}
                      onChange={(e) => setAedcPrepaid(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Aedc Postpaid
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      type="number"
                      placeholder="Input Units"
                      className={classes.textField}
                      size="small"
                      value={aedcPostpaid}
                      onChange={(e) => setAedcPostpaid(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Eedc Prepaid
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      type="number"
                      placeholder="Input Units"
                      className={classes.textField}
                      size="small"
                      value={eedcPrepaid}
                      onChange={(e) => setEedcPrepaid(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Eedc Postpaid
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      type="number"
                      placeholder="Input Units"
                      className={classes.textField}
                      size="small"
                      value={eedcPostpaid}
                      onChange={(e) => setEedcPostpaid(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Accesspower
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      type="number"
                      placeholder="Input Units"
                      className={classes.textField}
                      size="small"
                      value={accesspower}
                      onChange={(e) => setAccesspower(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Cel
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      type="number"
                      placeholder="Input Units"
                      className={classes.textField}
                      size="small"
                      value={cel}
                      onChange={(e) => setCel(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Bank Collections
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      type="number"
                      placeholder="Input Units"
                      className={classes.textField}
                      size="small"
                      value={bankCollections}
                      onChange={(e) => setBankCollections(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      USSD Collections
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      type="number"
                      placeholder="Input Units"
                      className={classes.textField}
                      size="small"
                      value={ussdCollections}
                      onChange={(e) => setUssdCollections(e.target.value)}
                    />
                  </FormControl>
                </Grid>

              </Grid>
            </Box>

            <Box>
              <Button
                variant="contained"
                className={classes.confirmBtn}
                onClick={handleEditTransaction}
                disableElevation
                disabled={loading || isLoading}
              >
                {isLoading ? <CircularProgress /> : "Set Commissions"}
              </Button>
            </Box>
          </DialogContent>
        ) : (
          <CircularProgress />
        )}
      </Dialog>
    </div>
  );
}
