/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import { Card, CardHeader, Container, Row } from "reactstrap";
import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  TextField,
  MenuItem,
  CircularProgress,
  IconButton,
  Typography,
  FormControl,
  Box,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CryptoJS from "crypto-js";
import { CSVLink } from "react-csv";

// core components
import Header from "components/Headers/Header.jsx";
import Swal from "sweetalert2";
import exportIcon from "../../assets/img/icons/export.png";
import TransferItem from "./TransferItem";

const useStyles = makeStyles((theme) => ({
  root: {},
  hide: {
    color: "white",
  },
}));

const Transfers = (props) => {
  const classes = useStyles();
  // const [startDate, setStartDate] = useState(
  //   moment().subtract(240, "days").format("Y-MM-DD")
  // );
  // const [endDate, setEndDate] = useState(moment().format("Y-MM-DD"));
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [transactionStatus, setTransactionStatus] = useState("");
  const [receiver, setReceiver] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [amountModifier, setAmountModifier] = useState("=");
  const [amount, setAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [disco, setDisco] = useState("");
  const [bank, setBank] = useState("");
  const [category, setCategory] = useState("");
  const [apiUsed, setApiUsed] = useState("");
  const [source, setSource] = useState("");
  const [merchantId, setMerchantId] = useState("");
  const [platform, setPlatform] = useState("");
  const [username, setUsername] = useState("");
  const [adeptAccount, setAdeptAccount] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [allTransactions, setAllTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState("");
  const [banks, setBanks] = useState([]);
  const [apisUsed, setApisUsed] = useState([]);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [paymentRef, setPaymentRef] = useState("");

  const clickRef = useRef(null);

  useEffect(() => {
    if (allTransactions.length) {
      clickRef.current.link.dispatchEvent(
        new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: true,
          buttons: 1,
        })
      );
    }
  }, [allTransactions]);

  const handleGetBanks = async () => {
    try {
      const passcode = CryptoJS.HmacSHA512("banks", "12CMS34Sx").toString();

      const body = {
        passcode,
      };

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/transfer/banks.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const { message, status, data } = await response.json();
      if (status === "00") {
        setBanks(data);
      } else if (status === "99") {
        Swal.fire({
          icon: "error",
          text: message,
          position: "top-end",
          confirmButtonColor: "#52ab64",
        });
        sessionStorage.clear();
        props.history.replace("/auth/login");
      } else {
        Swal.fire({
          icon: "error",
          text: message,
          position: "top-end",
          confirmButtonColor: "#52ab64",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Unable to connect",
        text: "Please check your internet connection and try again",
        confirmButtonColor: "#52ab64",
      });
    }
  };

  const handleGetApis = async () => {
    try {
      const passcode = CryptoJS.HmacSHA512("apis", "12CMS34Sx").toString();

      const body = {
        passcode,
      };

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/transfer/apis.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const { message, status, data } = await response.json();
      if (status === "00") {
        setApisUsed(data);
      } else if (status === "99") {
        Swal.fire({
          icon: "error",
          text: message,
          position: "top-end",
          confirmButtonColor: "#52ab64",
        });
        sessionStorage.clear();
        props.history.replace("/auth/login");
      } else {
        Swal.fire({
          icon: "error",
          text: message,
          position: "top-end",
          confirmButtonColor: "#52ab64",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Unable to connect",
        text: "Please check your internet connection and try again",
        confirmButtonColor: "#52ab64",
      });
    }
  };

  const handleGetTransactions = async (
    event,
    isNext,
    paginated = true,
    isRefresh
  ) => {
    event && event.preventDefault();
    if (!startDate) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Start Date is required",
        confirmButtonColor: "#52ab64",
      });
      return;
    } else if (phone && phone.length !== 11) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Phone no. must be 11 digits",
        confirmButtonColor: "#52ab64",
      });
      return;
    } else {
      if (!paginated) {
        setExportLoading(true);
      } else {
        setLoading(true);
      }

      try {
        const token = sessionStorage.getItem("token");
        const passcode = CryptoJS.HmacSHA512(token, "12CMS34Sx").toString();

        let page = 1;

        if (isRefresh === true) {
          page = currentPage;
        } else if (isNext === false) {
          page = currentPage - 1;
        } else if (isNext === true) {
          page = currentPage + 1;
        }

        let paginatedValue = "yes";

        if (!paginated) {
          paginatedValue = "no";
        }

        const data = {
          token,
          passcode,
          start_date: startDate,
          end_date: endDate,
          receiver,
          status: transactionStatus,
          transaction_id: transactionId,
          amount_modifier: amountModifier,
          amount,
          payment_method: paymentMethod,
          bank,
          category,
          api_used: apiUsed,
          source,
          merchant_id: merchantId,
          platform,
          username,
          // adept_account_number: adeptAccount,
          page,
          paginated: paginatedValue,
          phone,
          email,
          payment_ref: paymentRef,
        };

        if (!paginated) {
          delete data.page;
        }

        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/transfer/list.php`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );
        const {
          message,
          status,
          transactions: transactionsData,
          current_page,
          total_pages,
        } = await response.json();
        if (status === "00" && paginated) {
          setTransactions(transactionsData);
          setCurrentPage(current_page);
          console.log("its true");
          setTotalPages(total_pages);
        } else if (status === "00" && !paginated) {
          setAllTransactions(transactionsData);
          console.log("called");
        } else if (status === "99") {
          Swal.fire({
            icon: "error",
            text: message,
            position: "top-end",
            confirmButtonColor: "#52ab64",
          });
          sessionStorage.clear();
          props.history.replace("/auth/login");
        } else {
          Swal.fire({
            icon: "error",
            text: message,
            position: "top-end",
            confirmButtonColor: "#52ab64",
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Unable to connect",
          text: "Please check your internet connection and try again",
          confirmButtonColor: "#52ab64",
        });
      } finally {
        if (!paginated) {
          setExportLoading(false);
        } else {
          setLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    handleGetBanks();
    handleGetApis();
  }, []);

  const handleClear = () => {
    setStartDate("");
    setEndDate("");
    setReceiver("");
    setTransactionId("");
    setAmountModifier("=");
    setAmount("");
    setPaymentMethod("");
    // setDisco("");
    setBank("");
    setCategory("");
    setApiUsed("");
    setSource("");
    setMerchantId("");
    setPlatform("");
    setUsername("");
    setAdeptAccount("");
    setTransactionStatus("");
  };

  return (
    <div className={classes.printContainer}>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <div className="col">
            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0 pb-0">
                <h3 className="text-white mb-0">Transaction List</h3>
              </CardHeader>

              <div
                className={classes.box}
                style={{ marginTop: 16, padding: 8 }}
              >
                <h1 style={{ marginBottom: 16 }}>TRANSFER TRANSACTIONS LIST</h1>
                {loading && (
                  <div style={{ textAlign: "center", marginBottom: 32 }}>
                    <CircularProgress />
                  </div>
                )}
                <form>
                  <Grid container item spacing={1}>
                    <Grid container item sm={10}>
                      <Grid container item xs={12} spacing={1}>
                        <Grid item xs={12} lg={6}>
                          <FormControl>
                            <label component="legend" className={classes.label}>
                              <span>Start Date</span>
                            </label>
                            <TextField
                              type="date"
                              variant="outlined"
                              placeholder="dd/mm/yy"
                              size="small"
                              value={startDate}
                              fullWidth
                              style={{
                                background: "#FFFFFF",
                                borderRadius: 0,
                              }}
                              onChange={(e) => setStartDate(e.target.value)}
                            />
                          </FormControl>

                          <FormControl>
                            <label
                              component="legend"
                              className={classes.between}
                            >
                              <span>End Date</span>
                            </label>
                            <TextField
                              type="date"
                              variant="outlined"
                              placeholder="dd/mm/yy"
                              size="small"
                              value={endDate}
                              fullWidth
                              style={{
                                background: "#FFFFFF",
                              }}
                              onChange={(e) => setEndDate(e.target.value)}
                            />
                          </FormControl>
                        </Grid>

                        <Grid container item xs={6} lg={2}>
                          <Grid item xs={5}>
                            <FormControl
                              variant="outlined"
                              fullWidth
                              className={classes.formControl2}
                            >
                              <label component="legend" className={classes.end}>
                                {<span className={classes.hide}>.</span>}
                              </label>
                              <TextField
                                select
                                label=""
                                fullWidth
                                variant="outlined"
                                size="small"
                                style={{ background: "#FFFFFF" }}
                                onChange={(e) =>
                                  setAmountModifier(e.target.value)
                                }
                                value={
                                  amountModifier === "=" ? "=" : amountModifier
                                }
                              >
                                <MenuItem value={"="}>{"="}</MenuItem>
                                <MenuItem value={"<"}>{"<"}</MenuItem>
                                <MenuItem value={">"}>{">"}</MenuItem>
                              </TextField>
                            </FormControl>
                          </Grid>
                          <Grid item xs={7}>
                            <FormControl>
                              <label
                                component="legend"
                                className={classes.between}
                              >
                                <span>Amount</span>
                              </label>
                              <TextField
                                type="number"
                                variant="outlined"
                                size="small"
                                fullWidth
                                placeholder="0.00"
                                value={amount}
                                style={{
                                  background: "#FFFFFF",
                                }}
                                onChange={(e) => setAmount(e.target.value)}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid item xs={6} lg={3}>
                          <FormControl>
                            <label
                              className={classes.between}
                              component="legend"
                            >
                              <span>Transaction ID</span>
                            </label>

                            <TextField
                              type="text"
                              variant="outlined"
                              size="small"
                              fullWidth
                              placeholder="Transaction ID"
                              style={{
                                background: "#FFFFFF",
                              }}
                              value={transactionId}
                              onChange={(e) => setTransactionId(e.target.value)}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* ROW 2 */}
                      <Grid
                        container
                        item
                        spacing={1}
                        style={{ paddingTop: 16, paddingRight: 8 }}
                      >
                        <Grid item xs={12} lg={3}>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            className={classes.formControl2}
                          >
                            <label
                              className={classes.end}
                              component="legend"
                            ></label>
                            <TextField
                              select
                              label="All Payment Methods"
                              fullWidth
                              variant="outlined"
                              size="small"
                              style={{ background: "#FFFFFF" }}
                              onChange={(e) => setPaymentMethod(e.target.value)}
                              value={paymentMethod}
                            >
                              <MenuItem value="">
                                <i>All</i>
                              </MenuItem>
                              <MenuItem value={"ussd"}>USSD</MenuItem>
                              <MenuItem value={"card"}>Card</MenuItem>
                              <MenuItem value={"wallet"}>Wallet</MenuItem>
                              <MenuItem value={"pos"}>POS</MenuItem>
                              <MenuItem value={"voucher"}>Voucher</MenuItem>
                              <MenuItem value={"transfer"}>Transfer</MenuItem>
                              <MenuItem value={"paystack"}>Paystack</MenuItem>
                              <MenuItem value={"other"}>Other</MenuItem>
                              <MenuItem value={"mcash"}>MCash</MenuItem>
                              <MenuItem value={"adept"}>Adept</MenuItem>
                              <MenuItem value={"direct"}>Direct</MenuItem>
                              <MenuItem value={"api"}>API</MenuItem>
                              <MenuItem value={"zenith"}>Zenith</MenuItem>
                              <MenuItem value={"wema"}>Wema</MenuItem>
                              <MenuItem value={"monnify"}>Monnify</MenuItem>
                            </TextField>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} lg={2}>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            style={{ height: "40px" }}
                          >
                            <label
                              className={classes.end}
                              component="legend"
                            ></label>
                            <TextField
                              select
                              label="All Banks"
                              fullWidth
                              variant="outlined"
                              size="small"
                              style={{ background: "#FFFFFF" }}
                              value={bank}
                              onChange={(e) => setBank(e.target.value)}
                            >
                              <MenuItem value="">
                                <i>All</i>
                              </MenuItem>
                              {banks &&
                                banks.map(({ code, title }, index) => (
                                  <MenuItem value={code} key={index}>
                                    {title}
                                  </MenuItem>
                                ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} lg={3}>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            style={{ height: "40px" }}
                          >
                            <label
                              className={classes.end}
                              component="legend"
                            ></label>

                            <TextField
                              select
                              label="All Categories"
                              fullWidth
                              variant="outlined"
                              size="small"
                              style={{ background: "#FFFFFF" }}
                              value={category}
                              onChange={(e) => setCategory(e.target.value)}
                            >
                              <MenuItem value="">
                                <i>All</i>
                              </MenuItem>
                              <MenuItem value={"user"}>User</MenuItem>
                              <MenuItem value={"vendor"}>Vendor</MenuItem>
                              <MenuItem value={"corporate"}>Corporate</MenuItem>
                              w{" "}
                            </TextField>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} lg={3}>
                          <FormControl variant="outlined" fullWidth>
                            <label
                              className={classes.end}
                              component="legend"
                            ></label>
                            <TextField
                              select
                              label="API Used"
                              fullWidth
                              variant="outlined"
                              size="small"
                              style={{ background: "#FFFFFF" }}
                              onChange={(e) => setApiUsed(e.target.value)}
                              value={apiUsed}
                            >
                              <MenuItem value="">
                                <i>All</i>
                              </MenuItem>
                              {apisUsed && apisUsed.length > 0
                                ? apisUsed.map(({ code, title }) => (
                                    <MenuItem value={code} key={title}>
                                      {title}
                                    </MenuItem>
                                  ))
                                : ""}
                            </TextField>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* ROW 3 */}
                      <Grid
                        container
                        item
                        spacing={1}
                        style={{ paddingTop: 16, paddingRight: 8 }}
                      >
                        <Grid item xs={12} lg={3}>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            className={classes.formControl2}
                          >
                            <label
                              className={classes.end}
                              component="legend"
                            ></label>
                            <TextField
                              select
                              label="Status"
                              fullWidth
                              variant="outlined"
                              size="small"
                              style={{ background: "#FFFFFF" }}
                              onChange={(e) =>
                                setTransactionStatus(e.target.value)
                              }
                              value={transactionStatus}
                            >
                              <MenuItem value="">
                                <i>All</i>
                              </MenuItem>
                              <MenuItem value={"fulfilled"}>Fulfilled</MenuItem>
                              <MenuItem value={"pending"}>Pending</MenuItem>
                              <MenuItem value={"cancelled"}>Cancelled</MenuItem>
                              <MenuItem value={"refunded"}>Refunded</MenuItem>
                              <MenuItem value={"in progress"}>
                                In Progress
                              </MenuItem>
                              <MenuItem value={"paid"}>Paid</MenuItem>
                              <MenuItem value={"reversed"}>Reversed</MenuItem>
                              <MenuItem value={"failed"}>Failed</MenuItem>
                              <MenuItem value={"suspended"}>Suspended</MenuItem>
                            </TextField>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} lg={4}>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            style={{ height: "40px" }}
                          >
                            <label
                              className={classes.end}
                              component="legend"
                            ></label>
                            <TextField
                              select
                              label="Platform"
                              fullWidth
                              variant="outlined"
                              size="small"
                              style={{ background: "#FFFFFF" }}
                              value={platform}
                              onChange={(e) => setPlatform(e.target.value)}
                            >
                              <MenuItem value="">
                                <i>All</i>
                              </MenuItem>
                              <MenuItem value={"android"}>Android</MenuItem>
                              <MenuItem value={"desktop"}>Desktop</MenuItem>
                              <MenuItem value={"vendor profile web"}>
                                Vendor Profile Web
                              </MenuItem>
                              <MenuItem value={"ios"}>IOS</MenuItem>
                              <MenuItem value={"api"}>API</MenuItem>
                              <MenuItem value={"corporate profile web"}>
                                Corporate Profile Web
                              </MenuItem>
                              <MenuItem value={"user profile web"}>
                                User Profile Web
                              </MenuItem>
                              <MenuItem value={"website"}>Website</MenuItem>
                              <MenuItem value={"ussd"}>USSD</MenuItem>
                              <MenuItem value={"pos"}>POS</MenuItem>
                              <MenuItem value={"whatsapp"}>Whatsapp</MenuItem>
                            </TextField>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} lg={4}>
                          <FormControl variant="outlined" fullWidth>
                            <label
                              className={classes.end}
                              component="legend"
                            ></label>
                            <TextField
                              select
                              label="Source"
                              fullWidth
                              variant="outlined"
                              size="small"
                              style={{ background: "#FFFFFF" }}
                              onChange={(e) => setSource(e.target.value)}
                              value={source}
                            >
                              <MenuItem value="">
                                <i>All</i>
                              </MenuItem>
                              {/* <MenuItem value={"irecharge"}>iRecharge</MenuItem> */}
                              <MenuItem value={"user"}>User</MenuItem>
                              <MenuItem value={"agent"}>Agent</MenuItem>
                              {/* <MenuItem value={"partner"}>Partner</MenuItem> */}
                              <MenuItem value={"corporate"}>Corporate</MenuItem>
                              {/* <MenuItem value={"plugin"}>Plugin</MenuItem> */}
                              {/* <MenuItem value={"ussd"}>USSD</MenuItem> */}
                              {/* <MenuItem value={"api - union"}>
                                Api - Union
                              </MenuItem>
                              <MenuItem value={"api - zenith"}>
                                Api - Zenith
                              </MenuItem> */}
                              {/* <MenuItem value={"adept"}>Adept</MenuItem> */}
                              {/* <MenuItem value={"direct"}>Direct</MenuItem>
                              <MenuItem value={"kedco"}>Kedco</MenuItem>
                              <MenuItem value={"guest"}>Guest</MenuItem> */}
                            </TextField>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* ROW 4 */}
                      <Grid
                        container
                        item
                        xs={12}
                        spacing={1}
                        style={{ marginTop: 16 }}
                      >
                        <Grid item xs={6} lg={3}>
                          <FormControl>
                            <label
                              className={classes.between}
                              component="legend"
                            >
                              <span>Merchant ID</span>
                            </label>
                            <TextField
                              type="text"
                              variant="outlined"
                              size="small"
                              fullWidth
                              placeholder="Merchant ID"
                              style={{
                                background: "#FFFFFF",
                              }}
                              value={merchantId}
                              onChange={(e) => setMerchantId(e.target.value)}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                          <FormControl style={{ width: "100%" }}>
                            <label
                              className={classes.between}
                              component="legend"
                            >
                              <span>Receiver</span>
                            </label>
                            <TextField
                              type="text"
                              variant="outlined"
                              size="small"
                              fullWidth
                              placeholder="Account No."
                              style={{
                                background: "#FFFFFF",
                              }}
                              value={receiver}
                              onChange={(e) => setReceiver(e.target.value)}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} lg={2}>
                          <FormControl>
                            <label
                              className={classes.between}
                              component="legend"
                            >
                              <span>Username</span>
                            </label>
                            <TextField
                              type="text"
                              variant="outlined"
                              size="small"
                              fullWidth
                              placeholder="Username"
                              style={{
                                background: "#FFFFFF",
                              }}
                              value={username}
                              onChange={(e) => setUsername(e.target.value)}
                            />
                          </FormControl>
                        </Grid>
                        {/* <Grid item xs={6} lg={3}>
                          <FormControl>
                            <label
                              className={classes.between}
                              component="legend"
                            >
                              <span>Adept Account No.</span>
                            </label>
                            <TextField
                              type="text"
                              variant="outlined"
                              size="small"
                              fullWidth
                              placeholder="Adept Account Number"
                              style={{
                                background: "#FFFFFF",
                              }}
                              value={adeptAccount}
                              onChange={(e) => setAdeptAccount(e.target.value)}
                            />
                          </FormControl>
                        </Grid> */}
                      </Grid>
                      {/* ROW 5 */}
                      <Grid
                        container
                        item
                        xs={12}
                        spacing={1}
                        style={{ marginTop: 16 }}
                      >
                        <Grid item xs={12} lg={3}>
                          <FormControl style={{ width: "100%" }}>
                            <label
                              className={classes.between}
                              component="legend"
                            >
                              <span>Email</span>
                            </label>
                            <TextField
                              type="email"
                              variant="outlined"
                              size="small"
                              fullWidth
                              placeholder="Email"
                              style={{
                                background: "#FFFFFF",
                              }}
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                          <FormControl style={{ width: "100%" }}>
                            <label
                              className={classes.between}
                              component="legend"
                            >
                              <span>Phone Number</span>
                            </label>
                            <TextField
                              type="text"
                              variant="outlined"
                              size="small"
                              fullWidth
                              placeholder="Phone Number"
                              style={{
                                background: "#FFFFFF",
                              }}
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                          <FormControl style={{ width: "100%" }}>
                            <label
                              className={classes.between}
                              component="legend"
                            >
                              <span>Payment Reference</span>
                            </label>
                            <TextField
                              type="text"
                              variant="outlined"
                              size="small"
                              fullWidth
                              placeholder="Payment Reference"
                              style={{
                                background: "#FFFFFF",
                              }}
                              value={paymentRef}
                              onChange={(e) => setPaymentRef(e.target.value)}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      sm={2}
                      spacing={1}
                      justifyContent="center"
                    >
                      <Grid item xs={4} sm={12} style={{ textAlign: "right" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          style={{
                            color: "#fff",
                            width: "75%",
                          }}
                          onClick={(e) => {
                            handleGetTransactions(e);
                          }}
                          disabled={loading}
                        >
                          Sort
                        </Button>
                      </Grid>
                      <Grid item xs={4} sm={12} style={{ textAlign: "right" }}>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="large"
                          style={{
                            width: "75%",
                          }}
                          onClick={() => handleClear()}
                        >
                          Clear
                        </Button>
                      </Grid>

                      <Grid xs={4} sm={12} style={{ textAlign: "right" }}>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="large"
                          style={{ marginTop: 4, width: "75%" }}
                          disabled={exportLoading}
                          onClick={(event) =>
                            handleGetTransactions(event, false, false)
                          }
                        >
                          Export
                          {exportLoading && (
                            <CircularProgress size={20} color={"grey"} />
                          )}
                        </Button>

                        <Button
                          variant="outlined"
                          color="primary"
                          size="large"
                          style={{ marginTop: 4, visibility: "hidden" }}
                        >
                          <CSVLink
                            data={allTransactions}
                            filename={"transfer_transactions.csv"}
                            ref={clickRef}
                            onClick={() => console.log("engaged")}
                            style={{
                              textDecoration: "none",
                              color: "#28AA63",
                            }}
                          >
                            <img src={exportIcon} alt="" />
                            Export
                          </CSVLink>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
                {/* } */}
              </div>

              {/* TABLE */}
              <div style={{ margin: "32px 16px" }}>
                {transactions &&
                  transactions.length > 0 &&
                  transactions.map((transaction, index) => (
                    <TransferItem
                      key={index}
                      transaction={transaction}
                      refresh={(e) =>
                        handleGetTransactions(e, false, true, true)
                      }
                    />
                  ))}
                {transactions && transactions.length === 0 && !loading && (
                  <h4 style={{ textAlign: "center", padding: 16 }}>No Data</h4>
                )}
              </div>

              {loading && (
                <div style={{ textAlign: "center", margin: "16px 0" }}>
                  <CircularProgress />
                </div>
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <IconButton
                  aria-label="previous"
                  onClick={(e) => handleGetTransactions(e, false)}
                  disabled={(currentPage <= 1) | loading}
                >
                  <ArrowBackIosIcon />
                </IconButton>
                <IconButton
                  aria-label="next"
                  onClick={(e) => handleGetTransactions(e, true)}
                  disabled={(currentPage >= totalPages) | loading}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: 2,
                  paddingRight: 16,
                  paddingBottom: 16,
                }}
              >
                <Typography variant="caption">{`Page ${currentPage} of ${
                  totalPages === null ? "1" : totalPages
                }`}</Typography>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Transfers;
