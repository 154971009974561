/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.jsx";
import Login from "views/examples/Login.jsx";
import Failed from "views/examples/Failed.jsx";
import ForgotPassword from "views/examples/Password.jsx";
import DisputeResolutions from "./views/examples/DisputeResolutions";
import Fulfilled from "./views/examples/Fulfilled";
import Vtu from "./views/examples/Vtu";
import ChangePassword from "./views/examples/ChangePassword";
import Transfers from "./views/examples/Transfers";
import TvPage from "./views/examples/Tv";
import WalletPage from "./views/examples/Wallet";
import WalletTopupPage from "./views/examples/WalletTopup";
import MemberPage from "./views/examples/Member";
import MerchantsPage from "./views/examples/Merchants";

var routes = [
  // {
  //   path: "/index",
  //   name: "Dashboard",
  //   icon: "ni ni-bullet-list-67 text-blue",
  //   component: Index,
  //   layout: "/admin"
  // },
  {
    path: "/power",
    name: "Power",
    icon: "ni ni-bulb-61 text-primary",
    component: Fulfilled,
    layout: "/admin",
  },
  {
    path: "/vtu",
    name: "VTU",
    icon: "ni ni-bullet-list-67 text-primary",
    component: Vtu,
    layout: "/admin",
  },
  {
    path: "/transfers",
    name: "Transfers",
    icon: "ni ni-send text-primary",
    component: Transfers,
    layout: "/admin",
  },
  {
    path: "/tv",
    name: "TV",
    icon: "ni ni-tv-2 text-primary",
    component: TvPage,
    layout: "/admin",
  },
  {
    path: "/wallet",
    name: "Wallet",
    icon: "ni ni-folder-17 text-primary",
    component: WalletPage,
    layout: "/admin",
  },
  {
    path: "/wallet-topup",
    name: "Wallet Topup",
    icon: "ni ni-credit-card text-primary",
    component: WalletTopupPage,
    layout: "/admin",
  },
  {
    path: "/member",
    name: "Members",
    icon: "ni ni-circle-08 text-primary",
    component: MemberPage,
    layout: "/admin",
  },
  {
    path: "/merchants",
    name: "Merchants",
    icon: "ni ni-badge text-primary",
    component: MerchantsPage,
    layout: "/admin",
  },
  // {
  //   path: "/failed",
  //   name: "Failed Transactions",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: Failed,
  //   layout: "/admin"
  // },
  // {
  //   path: "/change-password",
  //   name: "Change Password",
  //   icon: "ni ni-lock-circle-open text-primary",
  //   component: ChangePassword,
  //   layout: "/admin"
  // },
  // {
  //   path: "/dispute-resolution",
  //   name: "Dispute Resolutions",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: DisputeResolutions,
  //   layout: "/admin"
  // },
  {
    path: "/login",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/reset_password",
    component: ForgotPassword,
    layout: "/auth",
  },
];
export default routes;
