import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import CancelIcon from "@material-ui/icons/Cancel";
import { IconButton, TextareaAutosize, Input } from "@material-ui/core";
import StoreIcon from "@material-ui/icons/Store";
import {
  Typography,
  TextField,
  Box,
  FormLabel,
  FormControl,
  CircularProgress,
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import RadioGroup from "@material-ui/core/RadioGroup";
import CryptoJS from "crypto-js";
import Swal from "sweetalert2";

const GreenRadio = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  formStyle: {
    border: "1px solid rgba(0,0,0,0.1)",
  },
  text: {
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  confirmBtn: {
    width: "100%",
    background: "#28AA63",
    color: "white",
    marginBottom: "1rem",
    padding: "1rem",
    "&:hover": {
      background: "#28AA63",
    },
  },
  logo: {
    textAlign: "center",
  },
  textfield: {
    padding: "1rem 1rem",
    border: "1px solid #F8FAFC",
  },
  formLabel: {
    textAlign: "left",
    fontSize: "14px",
    fontWeight: "500",
  },
  formControl2: {
    width: "100%",
    marginBottom: "1rem",
  },
  topTitle: {
    borderBottom: "1px solid #C4C4C4",
  },
  title: {
    paddingTop: "4px",
    paddingLeft: "4px",
  },
  content: {
    paddingTop: "2rem",
  },
  checkBox: {
    padding: "0px 2px 0px 6px",
  },
}));

export default function SuccessModalWalletTopup(props) {
  const classes = useStyles();

  const handleClose = (e, reason) => {
    e && e.preventDefault();
    if (reason && reason === "backdropClick") {
      return;
    }

    props.close();
  };

  // console.log(props.transaction);

  return (
    <div>
      <Dialog
        fullWidth
        open={props.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick="true"
      >
        <DialogTitle id="form-dialog-title" className={classes.topTitle}>
          <Grid container justify="space-between">
            <Grid item xs={10}>
              <Box>
                <Grid container alignItems="center">
                  <Grid item>{/* <StoreIcon /> */}</Grid>
                  <Grid item className={classes.title}></Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <IconButton onClick={handleClose}>
                <CancelIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        {
          <DialogContent className={classes.content}>
            <Box style={{ marginBottom: 32 }}>
              <Typography
                variant="h2"
                color="primary"
                style={{ textAlign: "center" }}
              >
                Success
              </Typography>
              <Typography
                variant="h6"
                component="p"
                style={{
                  textAlign: "center",
                  marginBottom: "2rem",
                  //   fontWeight: 700,
                }}
              >
                Transaction Redo is Successful
              </Typography>
              <Typography variant="body1">
                Transaction ID: <b>{props.data.transaction_id}</b>
              </Typography>
            </Box>
          </DialogContent>
        }
      </Dialog>
    </div>
  );
}
