import React, { useState } from "react";
import CryptoJS from "crypto-js";
import Swal from "sweetalert2";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Spinner,
} from "reactstrap";

function Login(props) {
  const [state, setState] = useState({
    username: "",
    password: "",
    isLoading: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (state.username == "" || state.password == "") {
      // props.history.push('/admin/fulfiled');
      // return
      alert("All fileds are composary");
      setState({
        ...state,
        isLoading: false,
      });
    } else {
      setState({
        ...state,
        isLoading: true,
      });

      try {
        const { username, password } = state;
        const key = CryptoJS.HmacSHA512(username, "12CMS34Sx").toString();

        // sessionStorage.setItem("passcode", key);

        const data = {
          username: username,
          password: password,
          passcode: key,
        };

        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/login.php`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );
        const jsonResponse = await response.json();
        if (jsonResponse.status === "00") {
          sessionStorage.setItem("token", jsonResponse.token);
          sessionStorage.setItem("isLoggedIn", true);
          Swal.fire({
            icon: "success",
            text: "Login successful",
            position: "top-end",
            showClass: {
              popup: "animated fadeInDown faster",
            },
            hideClass: {
              popup: "animated fadeOutUp faster",
            },
            confirmButtonColor: "#52ab64",
          });
          setState({
            ...state,
            isLoading: false,
          });
          props.history.push("/admin/power");
        } else if (jsonResponse.status === "99") {
          Swal.fire({
            icon: "error",
            text: jsonResponse.message,
            position: "top-end",
            confirmButtonColor: "#52ab64",
          });
          sessionStorage.clear();
          props.history.replace("/auth/login");
          setState({
            ...state,
            isLoading: false,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: jsonResponse.message,
            position: "top-end",
            confirmButtonColor: "#52ab64",
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Unable to connect",
          text: "Please check your internet connection and try again",
          confirmButtonColor: "#52ab64",
        });
      } finally {
        setState({
          ...state,
          isLoading: false,
        });
      }
    }
  };

  const handlePassword = (e) => {
    e.preventDefault();
    props.history.push("/auth/reset_password");
  };

  const { isLoading } = state;
  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center">
              <img
                alt="..."
                src={require("assets/img/brand/logo.png")}
                height="auto"
                width="150px"
              />
            </div>
            <br></br>
            <div className="text-center text-muted mb-4">
              <small>Sign in with credentials</small>
              {/* <button onClick={()=>dispatch(increment())}>Increment</button> */}
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-single-02 text-grey" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    onChange={handleChange}
                    name="username"
                    placeholder="Username"
                    type="text"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    onChange={handleChange}
                    name="password"
                    placeholder="Password"
                    type="password"
                  />
                </InputGroup>
              </FormGroup>
              <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
              </div>
              <div>
                <Spinner color="primary" />
              </div>
              <div className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  type="button"
                  onClick={handleLogin}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <span>
                      <i className="fas fa-spinner fa-spin"></i>Please wait...
                    </span>
                  ) : (
                    "Sign In"
                  )}
                </Button>
                <a
                  className="text-right"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  {/* <small onClick={this.handlePassword}>Forgot password?</small> */}
                </a>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}

export default Login;
