import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import CancelIcon from "@material-ui/icons/Cancel";
import { IconButton, TextareaAutosize } from "@material-ui/core";
import StoreIcon from "@material-ui/icons/Store";
import {
  Typography,
  TextField,
  Box,
  FormLabel,
  FormControl,
  CircularProgress,
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import RadioGroup from "@material-ui/core/RadioGroup";
import CryptoJS from "crypto-js";
import Swal from "sweetalert2";

const GreenRadio = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  formStyle: {
    border: "1px solid rgba(0,0,0,0.1)",
  },
  text: {
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  confirmBtn: {
    width: "100%",
    background: "#28AA63",
    color: "white",
    marginBottom: "1rem",
    padding: "1rem",
    "&:hover": {
      background: "#28AA63",
    },
  },
  logo: {
    textAlign: "center",
  },
  textfield: {
    padding: "1rem 1rem",
    border: "1px solid #F8FAFC",
  },
  formLabel: {
    textAlign: "left",
    fontSize: "14px",
    fontWeight: "500",
  },
  formControl2: {
    width: "100%",
    marginBottom: "1rem",
  },
  topTitle: {
    borderBottom: "1px solid #C4C4C4",
  },
  title: {
    paddingTop: "4px",
    paddingLeft: "4px",
  },
  content: {
    paddingTop: "2rem",
  },
  checkBox: {
    padding: "0px 2px 0px 6px",
  },
}));

export default function SetApprovalModalMerchant(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [command, setCommand] = useState("");
  const [chargeSignup, setChargeSignup] = useState("");
  const [remarks, setRemarks] = useState("");

  //   console.log(props.transaction);

//   const statusFields = [
//     "Fulfilled",
//     "Pending",
//     "Cancelled",
//     "In progress",
//     "Paid",
//     "Reversed",
//     "Retry",
//     "Suspended",
//     "Failed",
//   ];

  const handleSetApproval = async (event, id) => {
    event.preventDefault();
    setLoading(true);

    try {
      const token = sessionStorage.getItem("token");
      const passcode = CryptoJS.HmacSHA512(token, "12CMS34Sx").toString();

      const data = {
        passcode,
        id: props.data.id,
        token,
        command,
        charge_signup: chargeSignup,
        remarks
      };

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/merchant/approve.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const { message, status, data: transactionData } = await response.json();
      if (status === "00") {
        props.refresh();
        handleClose();
        Swal.fire({
          icon: "success",
          text: message,
          position: "center",
          confirmButtonColor: "#52ab64",
        });
        // console.log(transactionData);
        // setTransactionState(transactionData);
      } else if (status === "99") {
        Swal.fire({
          icon: "error",
          text: message,
          position: "top-end",
          confirmButtonColor: "#52ab64",
        });
        sessionStorage.clear();
        // props.history.replace("/auth/login");
      } else {

        Swal.fire({
          icon: "error",
          text: message,
          position: "top-end",
          confirmButtonColor: "#52ab64",
        });
      }
    } catch (error) {

      Swal.fire({
        icon: "error",
        title: "Unable to connect",
        text: "Please check your internet connection and try again",
        confirmButtonColor: "#52ab64",
      });
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  const handleClose = () => {
    props.close();
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={props.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.topTitle}>
          <Grid container justify="space-between">
            <Grid item xs={10}>
              <Box>
                <Grid container alignItems="center">
                  <Grid item>
                    <StoreIcon />
                  </Grid>
                  <Grid item className={classes.title}>
                    {loading ? (
                      <div>
                        <CircularProgress />
                        <span style={{ fontSize: 12, fontWeight: "normal" }}>
                          Loading..
                        </span>
                      </div>
                    ) : (
                      <Typography variant="h6">Approve/Reject/Reset <span style={{textTransform: "capitalize"}}>{props.data.merchant_name}</span></Typography>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <IconButton onClick={handleClose}>
                <CancelIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.content}>
        <Box>
            <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <FormControl
                variant="outlined"
                className={classes.formControl2}
                >
                <FormLabel
                    component="legend"
                    style={{ color: "#000", marginBottom: "5px" }}
                >
                    Action
                </FormLabel>
                <TextField
                    select
                    // label="Location"
                    placeholder="Choose command"
                    size="small"
                    variant="outlined"
                    value={command}
                    onChange={(e) => setCommand(e.target.value)}
                >
                    <MenuItem value="approve">Approve</MenuItem>
                    <MenuItem value="reject">Reject</MenuItem>
                    <MenuItem value="reset">Reset</MenuItem>
                </TextField>
                </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
                <FormControl
                variant="outlined"
                className={classes.formControl2}
                >
                <FormLabel
                    component="legend"
                    style={{ color: "#000", marginBottom: "5px" }}
                >
                    Charge signup fees?
                </FormLabel>
                <TextField
                //   defaultValue="yes"
                    select
                    // label="Location"
                    placeholder="Choose an option"
                    size="small"
                    variant="outlined"
                    value={chargeSignup}
                    onChange={(e) => setChargeSignup(e.target.value)}
                >
                    <MenuItem value="yes">Yes</MenuItem>
                    <MenuItem value="no">No</MenuItem>
                </TextField>
                </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
                <FormControl
                variant="outlined"
                className={classes.formControl2}
                >
                <FormLabel
                    component="legend"
                    style={{ color: "#000", marginBottom: "5px" }}
                >
                    Remarks
                </FormLabel>
                <TextField
                  variant="outlined"
                  // defaultValue={props.transaction.phone}
                  className={classes.textField}
                  placeholder="Enter remarks"
                  size="small"
                  // placeholder='Branch Phone Number'
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                />
                </FormControl>
            </Grid>

            </Grid>
        </Box>

        <Box>
            <Button
            variant="contained"
            className={classes.confirmBtn}
            onClick={handleSetApproval}
            disableElevation
            disabled={loading || isLoading || !command || !chargeSignup}
            // onClick={editBranchData}
            >
            {isLoading ? <CircularProgress /> : "Set Approval"}
            </Button>
        </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
