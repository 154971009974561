import {
    Box,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
  } from "@material-ui/core";
  import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
  import React from "react";
  
  const ViewModalMerchant = ({ transaction, openModal, setOpenModal }) => {
   
    const bold = { fontWeight: 700 };
  
    const handleClose = () => {
      setOpenModal(false); 
    };
    return (
      <>
        <Dialog
          open={openModal}
          fullWidth
          maxWidth={"sm"}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{
            "& .MuiDialog-paper": {
              px: "1.2em",
              py: "1em",
              borderRadius: "8px",
            },
          }}
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{
              fontSize: "18px",
              fontWeight: 500,
              color: "grey.dark",
              marginBottom: "1em",
              justifyContent: "flex-end",
              display: "flex",
              "& svg": {
                padding: "0",
              },
            }}
          >
            <IconButton
              aria-label="close"
              size="large"
              style={{ fontSize: "1.4rem" }}
              onClick={handleClose}
            >
              <ClearRoundedIcon fontSize="inherit" />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{ color: "#525f7f" }}>
            {transaction ? (
              <>
                <Typography
                  component="p"
                  variant="h6"
                  style={{
                    fontWeight: 700,
                    textTransform: "uppercase",
                  }}
                >
                  {transaction.title}
                </Typography>
                <Typography component="p" variant="body1">
                  <span style={bold}>Primary Contact Name</span>:{" "}
                  {transaction.primary_contact_name}
                </Typography>
                <Typography component="p" variant="body1">
                  <span style={bold}>Phone</span>:{" "}
                  {transaction.phone}
                </Typography>
                {/* <Typography component="p" variant="body1">
                      <span style={bold}>Token</span>: {transaction.token}
                    </Typography>{" "} */}
                <Typography component="p" variant="body1">
                  <span style={bold}>Merchant Name</span>: {transaction.merchant_name}
                </Typography>{" "}
                <Typography component="p" variant="body1">
                  <span style={bold}>Email</span>:{" "}
                  {transaction.email}
                </Typography>{" "}
                <Typography component="p" variant="body1">
                  <span style={bold}>Address</span>:{" "}
                  {transaction.address}
                </Typography>{" "}

              </>
            ) : (
              <Box
                style={{
                  display: "flex",
                  paddingBlock: "2em",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </DialogContent>
        </Dialog>
      </>
    );
  };
  
  export default ViewModalMerchant;
  