import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Modal,
  Typography,
} from "@material-ui/core";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import React from "react";

const TransactionModalWalletTopup = ({
  transaction,
  openModal,
  setOpenModal,
}) => {
  // const {
  //   amount,
  //   amount_dispense,
  //   amount_paid,
  //   api_reference_id,
  //   api_used,
  //   category,
  //   commission_applied,
  //   customer_address,
  //   customer_name,
  //   device_id,
  //   disco,
  //   email,
  //   id,
  //   merchant_name,
  //   payment_category,
  //   payment_method,
  //   phone,
  //   platform_used,
  //   receiver,
  //   reference_num,
  //   registered_user,
  //   send_via_email,
  //   send_via_sms,
  //   source,
  //   status,
  //   time_dispensed,
  //   time_stamp,
  //   title,
  //   token,
  //   transaction_id,
  //   units,
  //   utility_reference,
  //   vended_by,
  // } = transaction;
  const bold = { fontWeight: 700 };

  const handleClose = () => {
    setOpenModal(false);
  };
  return (
    <>
      <Dialog
        open={openModal}
        fullWidth
        maxWidth={"sm"}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          "& .MuiDialog-paper": {
            px: "1.2em",
            py: "1em",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            fontSize: "18px",
            fontWeight: 500,
            color: "grey.dark",
            marginBottom: "1em",
            justifyContent: "flex-end",
            display: "flex",
            "& svg": {
              padding: "0",
            },
          }}
        >
          <IconButton
            aria-label="close"
            size="large"
            style={{ fontSize: "1.4rem" }}
            onClick={handleClose}
          >
            <ClearRoundedIcon fontSize="inherit" />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ color: "#525f7f" }}>
          {transaction ? (
            <>
              <Typography
                component="p"
                variant="h6"
                style={{
                  fontWeight: 700,
                  textTransform: "uppercase",
                }}
              >
                {transaction.title}
              </Typography>
              <Typography component="p" variant="body1">
                {transaction.time_stamp}
              </Typography>
              <Typography component="p" variant="body1">
                <span style={bold}>Reference Number</span>:{" "}
                {transaction.reference_num}
              </Typography>
              <Typography component="p" variant="body1">
                <span style={bold}>Transaction ID</span>:{" "}
                {transaction.transaction_id}
              </Typography>
              <Typography component="p" variant="body1">
                <span style={bold}>Payment Method</span>:{" "}
                {transaction.payment_method}
              </Typography>
              <Typography component="p" variant="body1">
                <span style={bold}>Source</span>: {transaction.source}
              </Typography>
              <Typography component="p" variant="body1">
                <span style={bold}>Status</span>: {transaction.status}
              </Typography>
              <Typography component="p" variant="body1">
                <span style={bold}>Payment Ref</span>: {transaction.payment_ref}
              </Typography>
              <Typography component="p" variant="body1">
                <span style={bold}>Amount</span>: {transaction.amount}
              </Typography>
              <Typography component="p" variant="body1">
                <span style={bold}>Amount Dispense</span>:{" "}
                {transaction.amount_dispense}
              </Typography>
              <Typography component="p" variant="body1">
                <span style={bold}>Funded By</span>: {transaction.funded_by}
              </Typography>
              <Typography component="p" variant="body1">
                <span style={bold}>Merchant Name</span>:{" "}
                {transaction.merchant_name}
              </Typography>
              <Typography component="p" variant="body1">
                <span style={bold}>Merchant ID</span>: {transaction.merchant_id}
              </Typography>
              <Typography component="p" variant="body1">
                <span style={bold}>Registered User ID</span>:{" "}
                {transaction.registered_user}
              </Typography>
              <Typography component="p" variant="body1">
                <span style={bold}>IP Address</span>: {transaction.ip_address}
              </Typography>
            </>
          ) : (
            <Box
              style={{
                display: "flex",
                paddingBlock: "2em",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TransactionModalWalletTopup;
