import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import CancelIcon from "@material-ui/icons/Cancel";
import { IconButton, TextareaAutosize } from "@material-ui/core";
import StoreIcon from "@material-ui/icons/Store";
import {
  Typography,
  TextField,
  Box,
  FormLabel,
  FormControl,
  CircularProgress,
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import RadioGroup from "@material-ui/core/RadioGroup";
import CryptoJS from "crypto-js";
import Swal from "sweetalert2";

const GreenRadio = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  formStyle: {
    border: "1px solid rgba(0,0,0,0.1)",
  },
  text: {
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  confirmBtn: {
    width: "100%",
    background: "#28AA63",
    color: "white",
    marginBottom: "1rem",
    padding: "1rem",
    "&:hover": {
      background: "#28AA63",
    },
  },
  logo: {
    textAlign: "center",
  },
  textfield: {
    padding: "1rem 1rem",
    border: "1px solid #F8FAFC",
  },
  formLabel: {
    textAlign: "left",
    fontSize: "14px",
    fontWeight: "500",
  },
  formControl2: {
    width: "100%",
    marginBottom: "1rem",
  },
  topTitle: {
    borderBottom: "1px solid #C4C4C4",
  },
  title: {
    paddingTop: "4px",
    paddingLeft: "4px",
  },
  content: {
    paddingTop: "2rem",
  },
  checkBox: {
    padding: "0px 2px 0px 6px",
  },
}));

export default function EditModalVtu(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [remark, setRemark] = useState("");
  const [email, setEmail] = useState("");
  const [utility, setUtility] = useState("");
  const [phone, setPhone] = useState("");
  const [meterToken, setMeterToken] = useState("");
  const [transactionStatus, setTransactionStatus] = useState("");
  const [vendorReference, setVendorReference] = useState("");
  const [enabled, setEnabled] = useState("");
  const [paymentRef, setPaymentRef] = useState("");
  const [merchantId, setMerchantId] = useState("");

  //   console.log(props.transaction);

  const statusFields = [
    "Fulfilled",
    "Pending",
    "Cancelled",
    "In progress",
    "Paid",
    "Reversed",
    "Retry",
    "Suspended",
    "Failed",
  ];

  useEffect(() => {
    if (props.transaction) {
      console.log(props.transaction.remark);
      setRemark(props.transaction.remark);
      setEmail(props.transaction.email);
      //   setUtility(props.transaction.utility_reference);
      //   setMeterToken(props.transaction.token);
      setPhone(props.transaction.phone);
      setTransactionStatus(props.transaction.status);
      setVendorReference(props.transaction.vendor_reference);
      props.transaction.payment_ref &&
        setPaymentRef(props.transaction.payment_ref);
      props.transaction.merchant_id &&
        setMerchantId(props.transaction.merchant_id);
    }
  }, [props.transaction]);

  const handleEditTransaction = async (event, id) => {
    event.preventDefault();
    setLoading(true);

    try {
      const token = sessionStorage.getItem("token");
      const passcode = CryptoJS.HmacSHA512(token, "12CMS34Sx").toString();

      const data = {
        passcode,
        id: props.transaction.id,
        token,
        vendor_reference: vendorReference,
        status: transactionStatus,
        // meter_token: meterToken,
        remark,
        phone,
        email,
        // utility_reference: utility,
        payment_ref: paymentRef,
        merchant_id: merchantId,
      };

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/vtu/edit.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const { message, status, data: transactionData } = await response.json();
      if (status === "00") {
        props.refresh();
        handleClose();
        Swal.fire({
          icon: "success",
          text: message,
          position: "center",
          confirmButtonColor: "#52ab64",
        });
        // console.log(transactionData);
        // setTransactionState(transactionData);
      } else if (status === "99") {
        Swal.fire({
          icon: "error",
          text: message,
          position: "top-end",
          confirmButtonColor: "#52ab64",
        });
        sessionStorage.clear();
        // props.history.replace("/auth/login");
      } else {
        Swal.fire({
          icon: "error",
          text: message,
          position: "top-end",
          confirmButtonColor: "#52ab64",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Unable to connect",
        text: "Please check your internet connection and try again",
        confirmButtonColor: "#52ab64",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    props.close();
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={props.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.topTitle}>
          <Grid container justify="space-between">
            <Grid item xs={10}>
              <Box>
                <Grid container alignItems="center">
                  <Grid item>
                    <StoreIcon />
                  </Grid>
                  <Grid item className={classes.title}>
                    {loading ? (
                      <div>
                        <CircularProgress />
                        <span style={{ fontSize: 12, fontWeight: "normal" }}>
                          Loading...
                        </span>
                      </div>
                    ) : (
                      <Typography variant="h6">Edit Transaction</Typography>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <IconButton onClick={handleClose}>
                <CancelIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        {props.transaction ? (
          <DialogContent className={classes.content}>
            <Box>
              <Grid container spacing={2}>
                {/* <Typography>{props.transaction.source}</Typography> */}
                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Phone Number
                    </FormLabel>

                    <TextField
                      variant="outlined"
                      defaultValue={props.transaction.phone}
                      className={classes.textField}
                      placeholder="Input Phone number"
                      size="small"
                      // placeholder='Branch Phone Number'
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Email
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      //   defaultValue={props.transaction.email}
                      className={classes.textField}
                      size="small"
                      placeholder="Input email address"
                      // placeholder='Branch Name'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                {/* <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Utility Reference
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      placeholder="Input utility reference"
                      //   defaultValue={props.transaction.utility_reference}
                      className={classes.textField}
                      size="small"
                      value={utility}
                      onChange={(e) => setUtility(e.target.value)}
                      // placeholder='Branch Address'
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Token
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      placeholder="Input token"
                      //   defaultValue={props.transaction.token}
                      className={classes.textField}
                      size="small"
                      value={meterToken}
                      onChange={(e) => setMeterToken(e.target.value)}
                      // placeholder='Branch Address'
                    />
                  </FormControl>
                </Grid> */}
                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Remark
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      placeholder="Input remark"
                      className={classes.textField}
                      size="small"
                      value={remark}
                      onChange={(e) => setRemark(e.target.value)}
                      // placeholder='Branch Address'
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Payment Reference
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      placeholder="Input Payment Reference"
                      className={classes.textField}
                      size="small"
                      value={paymentRef}
                      onChange={(e) => setPaymentRef(e.target.value)}
                      // placeholder='Branch Address'
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Merchant ID
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      placeholder="Input Merchant ID"
                      className={classes.textField}
                      size="small"
                      value={merchantId}
                      onChange={(e) => setMerchantId(e.target.value)}
                      // placeholder='Branch Address'
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Status
                    </FormLabel>
                    <TextField
                      id="outlined-select-currency"
                      defaultValue={props.transaction.status}
                      select
                      // label="Location"
                      placeholder="Input status"
                      size="small"
                      variant="outlined"
                      value={transactionStatus}
                      onChange={(e) => setTransactionStatus(e.target.value)}
                    >
                      {statusFields.map((field) => {
                        return <MenuItem value={field}>{field}</MenuItem>;
                      })}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl2}
                  >
                    <FormLabel
                      component="legend"
                      style={{ color: "#000", marginBottom: "5px" }}
                    >
                      Vendor Reference
                    </FormLabel>
                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={3}
                      value={vendorReference}
                      placeholder="Vendor Reference"
                      style={{ width: "100%" }}
                      onChange={(e) => setVendorReference(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                {/* <Grid item xs={12} md={6}>
                                <FormControl variant="outlined" className={classes.formControl2}>
                                    <TextField
                                        variant="outlined"
                                        className={classes.textField}
                                        size='small'
                                        placeholder='Opening time'
                                    />
                                </FormControl>
                            </Grid> */}

                {/* <Grid item xs={12} md={6}>
                                <FormControl variant="outlined" className={classes.formControl2}>
                                    <TextField
                                        variant="outlined"
                                        className={classes.textField}
                                        size='small'
                                        placeholder='Closing time'
                                    />
                                </FormControl>
                            </Grid> */}

                {/* <Grid item xs={12} md={6}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend" style={{ color: "#000" }}>
                      Time Restricton?
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="time_restriction"
                      name="restriction"
                      value={timeRestricted}
                      onChange={(e) => setTimeRestricted(e.target.value)}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio color="primary" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio color="primary" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend" style={{ color: "#000" }}>
                      Enable Branch?
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="enable_branch"
                      name="enable"
                      value={enabled}
                      onChange={(e) => setEnabled(e.target.value)}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio color="primary" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio color="primary" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid> */}
              </Grid>
            </Box>

            <Box>
              <Button
                variant="contained"
                className={classes.confirmBtn}
                onClick={handleEditTransaction}
                disableElevation
                disabled={loading || isLoading}
                // onClick={editBranchData}
              >
                {isLoading ? <CircularProgress /> : "Edit Transaction"}
              </Button>
            </Box>
          </DialogContent>
        ) : (
          <CircularProgress />
        )}
      </Dialog>
    </div>
  );
}
