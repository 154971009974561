import React, { useState } from "react";
import {
  Grid,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import CryptoJS from "crypto-js";
import Swal from "sweetalert2";
import RedoModal from "../../components/Modal/RedoModal";
import TransactionModalWalletTopup from "../../components/Modal/TransactionModalWalletTopup";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EditModalWalletTopup from "../../components/Modal/EditModalWalletTopup";
import RedoModalWalletTopup from "../../components/Modal/RedoModalWalletTopup";

const WalletTopupItem = ({
  transaction: {
    amount,
    id,
    title,
    time_stamp,
    reference_num,
    transaction_id,
    payment_method,
    source,
    status,
    payment_ref,
    amount_dispense,
    funded_by,
    merchant_name,
    merchant_id,
  },
  refresh,
}) => {
  const bold = { fontWeight: 700 };
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  //   const [openViewLogModal, setOpenViewLogModal] = React.useState(false);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  //   const [openRefundModal, setOpenRefundModal] = React.useState(false);
  const [openRedoModal, setOpenRedoModal] = React.useState(false);
  const [transactionState, setTransactionState] = useState(null);
  //   const [logState, setLogState] = useState(null);
  //   const [refundData, setRefundData] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleModal = (event) => {
    handleClose();
    setOpenModal(true);
    handleGetTransaction(event, id);
  };
  const handleEditModal = (event) => {
    handleClose();
    setOpenEditModal(true);
    handleGetTransaction(event, id);
    // handleGetTransaction(event, id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //   const handleViewLogModal = (event) => {
  //     setLogState(null);
  //     handleClose();
  //     setOpenViewLogModal(true);
  //     handleViewLog(event, id);
  //   };

  //   const handleRefundModal = () => {
  //     setRefundData({ id, title });
  //     handleClose();
  //     setOpenRefundModal(true);
  //     // handleGetTransaction(event, id);
  //   };

  const handleRedoModal = () => {
    handleClose();
    setOpenRedoModal(true);
  };

  // console.log(transactionState);

  const handleGetTransaction = async (event, id) => {
    event.preventDefault();
    setLoading(true);

    try {
      const token = sessionStorage.getItem("token");
      const passcode = CryptoJS.HmacSHA512(token, "12CMS34Sx").toString();

      const data = {
        passcode,
        id,
        token,
      };

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/wallet_topup_transaction/view.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const { message, status, data: transactionData } = await response.json();
      if (status === "00") {
        setTransactionState(transactionData);
      } else if (status === "99") {
        Swal.fire({
          icon: "error",
          text: message,
          position: "top-end",
          confirmButtonColor: "#52ab64",
        });
        sessionStorage.clear();
        // props.history.replace("/auth/login");
      } else {
        Swal.fire({
          icon: "error",
          text: message,
          position: "top-end",
          confirmButtonColor: "#52ab64",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Unable to connect",
        text: "Please check your internet connection and try again",
        confirmButtonColor: "#52ab64",
      });
    } finally {
      setLoading(false);
    }
  };

  //   const handleViewLog = async (event, id) => {
  //     event.preventDefault();
  //     setLoading(true);

  //     try {
  //       const token = sessionStorage.getItem("token");
  //       const passcode = CryptoJS.HmacSHA512(token, "12CMS34Sx").toString();

  //       const data = {
  //         passcode,
  //         id,
  //         token,
  //       };

  //       const response = await fetch(
  //         `${process.env.REACT_APP_BASE_URL}/tv/view_log.php`,
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify(data),
  //         }
  //       );
  //       const { message, status, data: transactionData } = await response.json();
  //       if (status === "00") {
  //         setLogState(transactionData);
  //       } else if (status === "99") {
  //         Swal.fire({
  //           icon: "error",
  //           text: message,
  //           position: "top-end",
  //           confirmButtonColor: "#52ab64",
  //         });
  //         sessionStorage.clear();
  //         setOpenViewLogModal(false);
  //         // props.history.replace("/auth/login");
  //       } else {
  //         Swal.fire({
  //           icon: "error",
  //           text: message,
  //           position: "top-end",
  //           confirmButtonColor: "#52ab64",
  //         });
  //         setOpenViewLogModal(false);
  //       }
  //     } catch (error) {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Unable to connect",
  //         text: "Please check your internet connection and try again",
  //         confirmButtonColor: "#52ab64",
  //       });
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  return (
    <div>
      <Grid
        container
        justifyContent="space-between"
        style={{
          border: "1px solid #6D7893",
          borderRadius: 5,
          margin: "8px 0",
          padding: 8,
          backgroundColor: "#fcfcfc",
        }}
      >
        <Grid item xs={8}>
          <Typography
            component="p"
            variant="h6"
            style={{
              fontWeight: 700,
              textTransform: "uppercase",
            }}
          >
            {title}
          </Typography>
          <Typography component="p" variant="body1">
            {time_stamp}
          </Typography>
          <Typography component="p" variant="body1">
            <span style={bold}>Reference Number</span>: {reference_num}
          </Typography>
          <Typography component="p" variant="body1">
            <span style={bold}>Transaction ID</span>: {transaction_id}
          </Typography>
          <Typography component="p" variant="body1">
            <span style={bold}>Payment Method</span>: {payment_method}
          </Typography>
          <Typography component="p" variant="body1">
            <span style={bold}>Source</span>: {source}
          </Typography>
          <Typography component="p" variant="body1">
            <span style={bold}>Status</span>: {status}
          </Typography>
          <Typography component="p" variant="body1">
            <span style={bold}>Payment Ref</span>: {payment_ref}
          </Typography>
          <Typography component="p" variant="body1">
            <span style={bold}>Amount</span>: {amount}
          </Typography>
          <Typography component="p" variant="body1">
            <span style={bold}>Amount Dispense</span>: {amount_dispense}
          </Typography>
          <Typography component="p" variant="body1">
            <span style={bold}>Funded By</span>: {funded_by}
          </Typography>
          <Typography component="p" variant="body1">
            <span style={bold}>Merchant Name</span>: {merchant_name}
          </Typography>
          <Typography component="p" variant="body1">
            <span style={bold}>Merchant ID</span>: {merchant_id}
          </Typography>

          {/* <Typography component="p" variant="body1">
            <span style={bold}>Units</span>: {units}
          </Typography>{" "} */}
          {/* <Typography component="p" variant="body1">
            <span style={bold}>Utility Reference</span>: {utility_reference}
          </Typography>{" "} */}
        </Grid>
        <Grid item xs={4} style={{ textAlign: "right" }}>
          <IconButton
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            color="primary"
          >
            <MoreVertIcon fontSize="large" />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleModal}>View</MenuItem>
            <MenuItem onClick={handleEditModal}>Edit</MenuItem>
            <MenuItem onClick={handleRedoModal}>Redo</MenuItem>
          </Menu>
        </Grid>
      </Grid>

      {openModal && (
        <TransactionModalWalletTopup
          openModal={openModal}
          setOpenModal={setOpenModal}
          transaction={transactionState}
        />
      )}

      {/* {openViewLogModal && (
        <ViewLogModal
          openModal={openViewLogModal}
          setOpenModal={setOpenViewLogModal}
          log={logState}
        />
      )} */}
      {/* <EditBranch
        close={() => setOpenEditModal(false)}
        open={openEditModal}
        transaction={transactionState}
        refresh={refresh}
        type="vtu"
      /> */}

      <EditModalWalletTopup
        close={() => setOpenEditModal(false)}
        open={openEditModal}
        transaction={transactionState}
        refresh={refresh}
      />
      {/* {openRefundModal && (
        <RefundModalTv
          close={() => setOpenRefundModal(false)}
          open={openRefundModal}
          transaction={transactionState}
          data={refundData}
          refresh={refresh}
          type="vtu"
        />
      )} */}
      {openRedoModal && (
        <RedoModalWalletTopup
          close={() => setOpenRedoModal(false)}
          open={openRedoModal}
          transaction={transactionState}
          data={{ title, id }}
          refresh={refresh}
        />
      )}
    </div>
  );
};

export default WalletTopupItem;
