import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import CancelIcon from "@material-ui/icons/Cancel";
import { IconButton, TextareaAutosize, Input } from "@material-ui/core";
import StoreIcon from "@material-ui/icons/Store";
import {
  Typography,
  TextField,
  Box,
  FormLabel,
  FormControl,
  CircularProgress,
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import RadioGroup from "@material-ui/core/RadioGroup";
import CryptoJS from "crypto-js";
import Swal from "sweetalert2";

const GreenRadio = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  formStyle: {
    border: "1px solid rgba(0,0,0,0.1)",
  },
  text: {
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  confirmBtn: {
    width: "100%",
    background: "#28AA63",
    color: "white",
    marginBottom: "1rem",
    padding: "1rem",
    "&:hover": {
      background: "#28AA63",
    },
  },
  noBtn: {
    width: "100%",
    // background: "#28AA63",
    // color: "white",
    marginBottom: "1rem",
    padding: "1rem",
    // "&:hover": {
    //   background: "#28AA63",
    // },
  },
  logo: {
    textAlign: "center",
  },
  textfield: {
    padding: "1rem 1rem",
    border: "1px solid #F8FAFC",
  },
  formLabel: {
    textAlign: "left",
    fontSize: "14px",
    fontWeight: "500",
  },
  formControl2: {
    width: "100%",
    marginBottom: "1rem",
  },
  topTitle: {
    borderBottom: "1px solid #C4C4C4",
  },
  title: {
    paddingTop: "4px",
    paddingLeft: "4px",
  },
  content: {
    paddingTop: "2rem",
  },
  checkBox: {
    padding: "0px 2px 0px 6px",
  },
}));

export default function RefundModalSignupFees(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState("");
  const [vendorReference, setVendorReference] = useState("");

  const handleActiveMember = async (event, id) => {
    event.preventDefault();
    setLoading(true);

    try {
      const token = sessionStorage.getItem("token");
      const passcode = CryptoJS.HmacSHA512(token, "12CMS34Sx").toString();

      const data = {
        passcode,
        token,
        id: props.data.id,
      };

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/merchant/refund_signup_fee.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const { message, status, data: transactionData } = await response.json();
      if (status === "00") {
        props.refresh();
        Swal.fire({
          icon: "success",
          text: message,
          position: "center",
          confirmButtonColor: "#52ab64",
        });
        handleClose();
        // console.log(transactionData);
        // setTransactionState(transactionData);
      } else if (status === "99") {
        Swal.fire({
          icon: "error",
          text: message,
          position: "top-end",
          confirmButtonColor: "#52ab64",
        });
        handleClose();
        sessionStorage.clear();
        // props.history.replace("/auth/login");
      } else {
        Swal.fire({
          icon: "error",
          text: message,
          confirmButtonColor: "#52ab64",
        });
        handleClose();
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Unable to connect",
        text: "Please check your internet connection and try again",
        confirmButtonColor: "#52ab64",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = (e) => {
    e && e.preventDefault();

    props.close();
  };

  // console.log(props.transaction);

  return (
    <div>
      <Dialog
        fullWidth
        open={props.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.topTitle}>
          <Grid container justify="space-between">
            <Grid item xs={10}>
              <Box>
                <Grid container alignItems="center">
                  <Grid item>{/* <StoreIcon /> */}</Grid>
                  <Grid item className={classes.title}>
                    {
                      // loading ?
                      //  (
                      //   <div>
                      //     <CircularProgress />
                      //     <span style={{ fontSize: 12, fontWeight: "normal" }}>
                      //       Loading Details..
                      //     </span>
                      //   </div>
                      // ) :
                      // <Box>
                      //   <Typography
                      //     variant="h6"
                      //     style={{ textAlign: "center", marginBottom: "2rem" }}
                      //   >
                      //     Do you want to refund this transaction?
                      //   </Typography>
                      // </Box>
                    }
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <IconButton onClick={handleClose}>
                <CancelIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        {
          // props.transaction ?
          <DialogContent className={classes.content}>
            {/* <Box>
              <Typography
                variant="h6"
                style={{ textAlign: "center", marginBottom: "2rem" }}
              >
                Do you want to refund this transaction?
              </Typography>
            </Box> */}
            <Box>
              <Typography variant="h6" style={{ textAlign: "center" }}>
                Do you want to refund signup fees for this merchant? <br /> <Typography variant="h6" fontWeight={800} style={{textTransform: "capitalize"}}>{props.data.merchant_name}</Typography>
              </Typography>
              <Typography
                variant="h6"
                component="h6"
                style={{
                  textAlign: "center",
                  marginBottom: "2rem",
                  fontWeight: 700,
                }}
              >
                {props.data.title}
              </Typography>
            </Box>

            <form onSubmit={handleActiveMember}>
              <Box
                style={{
                  display: "flex",
                  marginTop: "2rem",
                }}
              >
                <Button
                  variant="outlined"
                  type="button"
                  className={classes.noBtn}
                  style={{
                    marginRight: "0.5em",
                  }}
                  disableElevation
                  onClick={handleClose}
                >
                  No
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.confirmBtn}
                  style={{
                    marginLeft: "0.5em",
                  }}
                  // onClick={handleRefundTransaction}
                  disableElevation
                  disabled={loading || isLoading}
                >
                  {loading ? <CircularProgress /> : "Yes"}
                </Button>
              </Box>
            </form>
          </DialogContent>
          // : (
          //   <CircularProgress />
          // )
        }
      </Dialog>
    </div>
  );
}
